import moment from 'moment';
import axios from '../../../lib/axios';
import metadataAxios from '../../../lib/metadataAxios'
import axiosInstance from 'axios';
import API_URL from '../../../config/api';
import { toggleLoading, toggleNotification, handleErrors, setPatientReportStatus, handleStatusCodes, setLoggedInUser, handleLoading, toggleReportLoading } from '../../../reducers/global-reducer';
import masthead_blue from '../../../assets/images/analytics-banner-inverted.svg';
import masthead_white from '../../../assets/images/analytics-banner-solid.svg';
import { setSitePreference } from '../tools/calendar';
import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import constants from '../../../config/constants';
import { datasetSelector, gpaSelector, timeBoundarySelector, gradeSelector } from '../selectors/datasetSelector';
import { logOut } from '../../../reducers/global-reducer';
import labels from '../../../config/localization';
import { setCurrentVisit, setHeaderType } from '../../Visits/modules/visits';
import { isFirefox, b64toBlob, isIE, isSafari, getValidSafariDate } from '../../../utils/download-utils'
import demoData from '../demoAnalyticsApi';
import visitsummaryStyles from '../components/VisitSummaryStyles';
import reportlogo from '../../../assets/images/android-icon-144x144.png';
import { roundNumber, uniformString } from '../tools/helpers';
import { getLocalizedDate } from '../../../utils/reports-date-localization';
import en_labels from '../../../config/en_labels';
import { clearPromises, promisesList } from '../../../utils/resolve-promises';
import demoSummary from '../../../assets/sample pdfs/demo_summary.pdf';
import demo2Summary from '../../../assets/sample pdfs/demo_2_summary.pdf'
import { getRouteStatus } from '../../../utils/parseJWT';
import tru from '../../../../src/tru_visitsummary.svg';
import { getDateFormat } from '../tools/helpers';

const SET_PROPS = 'SET_PROPS';
const requiredFields = ['group', 'body']
const requiredFieldText =
{
    group: 'Group',
    body: 'Body',
}

const medicationRequiredFields = ['group', 'body']
const medicationRequiredFieldText =
{
    group: 'Group',
    body: 'Body'
}

// function handleLoading() {
//     return (dispatch) => {
//         let allPromises = promisesList.filter(p => p)
//         Promise.all(allPromises).then(function (values) {
//             if (allPromises.length && allPromises.length == values.length) {
//                 clearPromises()
//                 dispatch(toggleLoading(false))
//             }
//         });
//     }
// }

function loadDemoData() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                loadingData: "pending",
                loadingCategories: "pending",
                loadingSections: "pending",
                loadingPatient: "pending",
                loadingRecommendations: "pending",
                loadingMedications: "pending",
                presentPatient: {},
                siteData: null
            }
        })
        setTimeout(() => {
            dispatch(setDemoHealthAnalytics())
        }, 500)
    }
}
function setDemoHealthAnalytics() {
    return (dispatch) => {
        let demoPath = window.location.pathname.indexOf('demo_1') == 1 ? 'demo_1' : 'demo_2';
        let presentPatient = demoData.patientInfo[demoPath]
        let siteData = demoData.siteData[demoPath]
        let terms = {}
        demoData.termTranslations.map(t => { terms[t.key] = t.termTranslationsDTOList.text })
        dispatch({
            type: SET_PROPS,
            payload: {
                presentPatient,
                loadingPatient: "finished",
                isDemo: true,
                isDemo1: window.location.pathname.indexOf('demo_1') == 1 ? true : false,
                isDemo2: window.location.pathname.indexOf('demo_2') == 1 ? true : false
            }
        })
        let updated_lastVisitDate = presentPatient.latestVisitDate ? moment(presentPatient.latestVisitDate).format('L') : 'no-visits'
        let dateOfBirth = (presentPatient.datePreference == constants.safari_invalid_date_format && isSafari())
            ? moment(getValidSafariDate(presentPatient.dob)).format('L')
            : moment(presentPatient.dob).format('L');
        let age = Math.floor(Math.abs(moment(updated_lastVisitDate).diff(dateOfBirth, 'years', true)))
        let age_round = roundNumber((Math.abs(moment(updated_lastVisitDate).diff(dateOfBirth, 'years', true))), 1)
        let dateFormat = siteData.datePreference;
        let lastVisitDate = presentPatient.latestVisitDate ? setSitePreference(dateFormat, presentPatient.latestVisitDate) : ''
        var yearForRecommendations = moment(lastVisitDate).format('DD-MM-YYYY');
        let bioInfo = constants.historyComponent.bioInfo;
        var biographical_info = {}
        window.location.pathname.indexOf('demo_1') == 1 ?
            biographical_info = {
                smoker: 'Past',
                hypertension_intervention: 'Current',
                high_cholesterol_intervention: 'No',
                glucose_tolerance_status: 'No'
            } :
            biographical_info = {
                smoker: 'Past',
                hypertension_intervention: 'No',
                high_cholesterol_intervention: 'No',
                glucose_tolerance_status: 'No'
            }
        dispatch({
            type: SET_PROPS,
            payload: {
                presentPatient: demoData.patientInfo[demoPath],
                age,
                siteData,
                lastVisitDate,
                visit_count: presentPatient.visit_count,
                bioInfo, biographical_info, dateFormat, yearForRecommendations,
                role: constants.logged_roles.CG,
                latestVisitInfo: presentPatient.latestVisitDate,
                isMenuActive: false,
                currentProvider: demoData.providerData[demoPath],
                terms,
                updated_lastVisitDate,
                age_round
            }
        })
        let recommendations = demoData.recommendationsInfo[demoPath].filter(s => s.isStopped != 1)
        let stoppedRecommendations = demoData.recommendationsInfo[demoPath].filter(s => s.isStopped == 1)
        dispatch(toggleLoading(true))
        metadataAxios.get(process.env.REACT_APP_MEDICATION_BLOB_URL).then((response) => {
            dispatch(toggleLoading(false))
            let sortableKey = constants.recommendation_groups
            let groups_recommendations = response.data.groupDTO.map((rec) => { return { id: rec.id, value: rec.name } }).sort((a, b) => {
                return sortableKey.indexOf(a.value) - sortableKey.indexOf(b.value);
            });
            dispatch({
                type: SET_PROPS,
                payload: {
                    all_recommendations_data: response.data,
                    groups_recommendations: groups_recommendations,
                    brand_list: setBrandList({ all_recommendations_data: response.data }, 'General Instructions'),
                    recommendation_list: setRecommendationList({ all_recommendations_data: response.data }, 'General Instructions'),
                    strengthTypes: response.data.strengthTypesDTO ? [...(constants.blankOption), ...response.data.strengthTypesDTO] : [],
                    loadingMedications: "finished",
                }
            })
        }).catch((error) => {
            dispatch(toggleLoading(false))
            dispatch(handleErrors(error))
        })

        dispatch({
            type: SET_PROPS,
            payload: {
                recommendations: recommendations,
                stoppedRecommendations: stoppedRecommendations,
                medications: recommendations ? recommendations.filter((rec) => constants.medication_groups.includes(rec.group)) : [],
                stoppedMedications: stoppedRecommendations ? stoppedRecommendations.filter((rec) => constants.medication_groups.includes(rec.group)) : [],
                isRecommendationsLoading: false,
                loadingRecommendations: "finished",
                recommendationForm: { group: 'General Instructions' },
                // all_recommendations_data: demoData.allRecommendationsInfo ? demoData.allRecommendationsInfo : [],
                // groups_recommendations: demoData.allRecommendationsInfo.value.groupDTO.map((rec) => { return { id: rec.id, value: rec.name } })
            }
        })
        dispatch(setAggregateDetails(demoData.visitsData[demoPath]))
        dispatch(setBiomarkersDemoData(demoData.historicCategoryInfo))
        // dispatch(setComparingChartsData(demoData.comparingChartsData[demoPath]))
        dispatch({
            type: SET_PROPS,
            payload: {
                category: demoPath == 'demo_1' ? demoData.analyticsInfo : demoData.analyticsInfo2,
                loadingCategories: "finished",
                loadingSections: "finished",
                Data: demoPath == 'demo_1' ? demoData.historicCategoryInfo : demoData.historicCategoryInfo2,
                allVisitData: demoData.visitsData[demoPath],
                currentPatientDocumentsList: demoData.documentsInfo[demoPath],
                metaRecommendations: demoPath == 'demo_1' ? _.head(demoData.historicCategoryInfo).metaRecommendations : [],
                noumenonReferences: demoData.noumenonReferenceInfo
            }
        })

        dispatch(dataSelector())
    }
}
export function setCurrentHealthAnalytics(patientId) {
    return (dispatch) => {
        dispatch(toggleLoading(false))
        dispatch({
            type: SET_PROPS,
            payload: {
                presentPatient: {},
                isDemo: false,
                currentPatientDocumentsList: [],
                Data: null,
                categories: null
            }
        })
        if (patientId) {
            dispatch(setAnalytics(patientId))
        } else {
            axios.get(API_URL.USERS_URL).then((userresponse) => {
                dispatch(setAnalytics(userresponse.data.patientId))
            }).catch(error => {
                dispatch(handleErrors(error))
            })
        }

    }
}
function toDataURL(url, name) {
    return (dispatch) => {

        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        [name]: reader.result
                    }
                })
                return (reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }
}
function setNoumenonReferences(locale) {
    return (dispatch, getState) => {
        axios.get(`${API_URL.NOUMENON_REFERENCES}?locale=${locale}`).then((response) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    noumenonReferences: response.data
                }
            })
        })
    }
}
function setAnalyticsData(patientId, isSummary, summaryVisitId, locale, history, isMobile) {
    return (dispatch, getState) => {
        axios.get(`${API_URL.PATIENTS_URL}/${patientId}`).then((response) => {
            let role = getState().global && getState().global.loggedInUser
                && getState().global.loggedInUser.role ? getState().global.loggedInUser.role
                : constants.logged_roles[localStorage.getItem(constants.role)]

            let presentPatient = { ...response.data };
            let updated_lastVisitDate = presentPatient.latestVisitDate ? moment(presentPatient.latestVisitDate).format('L') : 'no-visits'
            let dateOfBirth = (presentPatient.datePreference == constants.safari_invalid_date_format && isSafari())
                ? moment(getValidSafariDate(presentPatient.dob)).format('L')
                : moment(presentPatient.dob).format('L');
            let age = Math.floor(Math.abs(moment(updated_lastVisitDate).diff(dateOfBirth, 'years', true)))
            let age_round = roundNumber((Math.abs(moment(updated_lastVisitDate).diff(dateOfBirth, 'years', true))), 1)
            dispatch(getRecommendations(summaryVisitId ? summaryVisitId : presentPatient.latestVisitId, locale ? locale : response.data.patientLanguagePref ? response.data.patientLanguagePref : "en", patientId))
            dispatch(getTerms(locale ? locale : response.data.patientLanguagePref, isSummary))
            dispatch(setNoumenonReferences(locale ? locale : response.data.patientLanguagePref))
            dispatch(getCustomRecommendation(presentPatient.siteId))
            dispatch(getCustomSigs(presentPatient.siteId))
            axios.get(`${API_URL.SITE_URL}/${presentPatient.siteId}`).then((siteResponse) => {
                dispatch(getAnalyticsSurveyData(presentPatient.latestVisitId, locale ? locale : response.data.patientLanguagePref))
                let siteData = { ...siteResponse.data };
                let dateFormat = siteData.datePreference;
                let lastVisitDate = presentPatient.latestVisitDate ? setSitePreference(dateFormat, presentPatient.latestVisitDate) : ''
                var yearForRecommendations = moment(lastVisitDate).format('DD-MM-YYYY');
                if (siteData.enableDocuments) {
                    promisesList.push(axios.get(`${API_URL.PATIENT_DOCUMENTS_VISITS}?patientId=${patientId}`).then(response => {
                        dispatch({
                            type: SET_PROPS,
                            payload: {
                                currentPatientDocumentsList: (response.data.value && response.data.value.length
                                    && response.data.value.filter(s => s.visible == 1).length) ? response.data.value.filter(s => s.visible == 1) : null
                            }
                        })
                    }).catch(errors => {
                        dispatch(handleErrors(errors))
                        dispatch(handleStatusCodes(errors))
                    }))
                }
                dispatch(toDataURL(reportlogo, 'reportlogo'))
                dispatch(toDataURL(tru, 'tru'))

                dispatch({
                    type: SET_PROPS,
                    payload: {
                        presentPatient,
                        age,
                        siteData,
                        lastVisitDate,
                        updated_lastVisitDate,
                        visit_count: presentPatient.visit_count,
                        //biographical_info
                        dateFormat, yearForRecommendations, role,
                        latestVisitInfo: presentPatient.latestVisitDate,
                        isMenuActive: false,
                        age_round
                    }
                })
                dispatch(getAllRecommendations())
                dispatch(providerDetails(presentPatient.providerId))

                // dispatch(setBiomarkersOfAging(presentPatient.id,
                //     locale ? locale : response.data.patientLanguagePref ? response.data.patientLanguagePref : 'en'
                //     , summaryVisitId))

                dispatch(setAllVisitData(presentPatient.id, summaryVisitId, locale ? locale : response.data.patientLanguagePref ? response.data.patientLanguagePref : 'en'))

                dispatch(getAllCategories(summaryVisitId ? summaryVisitId : presentPatient.latestVisitId
                    , locale ? locale : response.data.patientLanguagePref ? response.data.patientLanguagePref : 'en'
                    , isSummary, history, isMobile))
                // dispatch(toggleLoading(false))
                // })
                // .catch((error) => {
                //     dispatch(handleErrors(error))
                //     dispatch(handleSummaryLoading(false))
                // })
            }).catch((error) => {
                dispatch(handleErrors(error))
                dispatch(handleSummaryLoading(false))
            })
        }).catch((error) => {
            dispatch(handleErrors(error))
            dispatch(handleStatusCodes(error))
            dispatch(handleSummaryLoading(false))
        })
    }
}
function setAnalytics(patientId, isSummary, visitId, locale, history, isMobile) {
    return (dispatch, getState) => {
        dispatch(setLoggedInUser())
        if (isSummary) {
            dispatch(toggleLoading(true))
            promisesList.push(dispatch(setAnalyticsData(patientId, isSummary, visitId, locale, history, isMobile)))
        } else {
            dispatch(setAnalyticsData(patientId, isSummary, visitId, locale))
        }
    }
}
export function providerDetails(id) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentProvider: {}
            }
        })
        axios.get(`${API_URL.PROVIDERS_URL}/${id}`).then((response) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentProvider: response.data
                }
            })
        }).catch((error) => {
            dispatch(handleErrors(error))
        })
    }
}
function setAggregateGraph(data) {
    return (dispatch, getState) => {
        let aggregateAgeUrl = constants.biographical_labels.artisan_route;
        let age = getState().analytics.age_round;
        let res = data ? Object.keys(data).map(ad => {
            if (_.head(data[ad]) != null && _.head(data[ad]).value != null) {
                return `n:${_.head(data[ad]).label}^v:${roundNumber(_.head(data[ad]).value, 1)}^b:${age}`
            }
        }) : ""
        aggregateAgeUrl = res ? `${aggregateAgeUrl}?data=${_.compact(res).join('|')}` : ''
        dispatch({
            type: SET_PROPS,
            payload: {
                aggregateAgeUrl
            }
        })
    }
}
function setAggregateDetails(data) {
    return (dispatch, getState) => {
        let currentSummaryVisit = getState().analytics.currentSummaryVisitId ? _.head(data.filter(d => d.id == getState().analytics.currentSummaryVisitId)) : null
        let currentPatientAging = currentSummaryVisit ? currentSummaryVisit : _.head(data);
        let analyticsAggregateChart = currentPatientAging ? currentPatientAging.ageData : null;
        // let aggregateAgeUrl = constants.biographical_labels.artisan_route;
        // let age = getState().analytics.age;
        let providerGender = getState().providers && getState().providers.currentPatient ? getState().providers.currentPatient.gender : null;
        // let res = analyticsAggregateChart ? Object.keys(analyticsAggregateChart).map(ad => {
        //     if (analyticsAggregateChart[ad] != "" && ad != "incite_trubiohealth_age" && ad != "glycanage" && ad != "epigenage") {
        //         return `n:${ad}^v:${Math.round(analyticsAggregateChart[ad])}^b:${age}`
        //     }
        // }) : ""
        // aggregateAgeUrl = res ? `${aggregateAgeUrl}?data=${_.compact(res).join('|')}` : ''
        dispatch({
            type: SET_PROPS,
            payload: {
                currentPatientAging,
                analyticsAggregateChart,
                // aggregateAgeUrl,
                providerGender
            }
        })
    }
}
function setComparingChartsData(data) {
    return (dispatch, getState) => {
        var biomarkerAgesList = _.compact(Object.keys(data).map(s => _.head(data[s]) ? _.head(data[s]).code.toLowerCase() : null));
        dispatch({
            type: SET_PROPS,
            payload: {
                historic_data_chart: data,
                loadingPatient: "finished",
                biomarkerAgesList
            }
        })
    }

}
export function setAllVisitData(patientId, summaryVisitId, locale) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentPatientAging: {}
            }
        })
        axios.get(API_URL.PATIENTS_VISTS_URL, { params: { patientId, loadAgeData: false } }).then((response) => {
            dispatch(setAggregateDetails(response.data))
            dispatch({
                type: SET_PROPS,
                payload: {
                    allVisitData: response.data ? response.data : '',
                    lastestVisitDataentry: response.data && _.last(response.data) ? _.last(response.data).dataEntry : ''
                }
            })
        }).catch((error) => {
            dispatch(handleErrors(error))
        })
        if (summaryVisitId) {
            let currentFollowUpCount = !isNaN(getState().analytics.currentTimepoint) ? getState().analytics.currentTimepoint : 0
            let query = `followUpCount=${currentFollowUpCount}&cultureInfo=${locale}&visitId=${summaryVisitId}`
            //let query = `followUpCount=${currentFollowUpCount}&cultureInfo=${locale}`
            axios.get(`${API_URL.FOLLOW_UP_DATA}/${patientId}?${query}`).then((response) => {
                // dispatch(handleLoading())
                let followupdata = response.data.filter(d => d.visitId == summaryVisitId && d.value && parseInt(d.value))

                // let culturedBiomarkers = {};
                // let groupByAges = _.groupBy(response.data, 'cultureLabel')
                // let ages = Object.keys(groupByAges);
                // for (let key of ages) {
                //     groupByAges[key] = groupByAges[key].filter(s => s.value != "0");
                //     groupByAges[key] = groupByAges[key].length > 0 ? _.last(groupByAges[key]) : {};
                //     _.isEmpty(groupByAges[key]) == false ? culturedBiomarkers[groupByAges[key].label] = groupByAges[key].cultureLabel : '';
                // }
                let historic_data = {}
                // let ageData = {}
                // let arrayObject = [];
                Object.keys(constants.biographical_labels.followup_ages).map(fp => {
                    historic_data[fp] = followupdata.filter(d => d.label == constants.biographical_labels.followup_ages[fp] && d.visitId == summaryVisitId)
                    // ageData = groupByAges[culturedBiomarkers[constants.biographical_labels.followup_ages[fp]]];
                    // arrayObject = [];
                    // ageData ? arrayObject.push(ageData) : '';
                    // historic_data[fp] = arrayObject;
                })
                dispatch(setComparingChartsData(historic_data))
            }).catch(errors => {
                dispatch(handleErrors(errors));
            })
        }
    }
}
export function setBiomarkersOfAging(patientId, locale, summaryVisitId, biomarkers_historic_data) {

    return (dispatch, getState) => {
        // dispatch(toggleLoading(true))
        // dispatch({
        //     type: SET_PROPS,
        //     payload: {
        //         currentPatientAging: {}
        //     }
        // })
        // axios.get(API_URL.PATIENTS_VISTS_URL, { params: { patientId, loadAgeData: false } }).then((response) => {
        //     dispatch(setAggregateDetails(response.data))
        //     dispatch({
        //         type: SET_PROPS,
        //         payload: {
        //             allVisitData: response.data ? response.data : '',
        //             lastestVisitDataentry: response.data && _.last(response.data) ? _.last(response.data).dataEntry : ''
        //         }
        //     })
        // }).catch((error) => {
        //     dispatch(handleErrors(error))
        // })
        if (!summaryVisitId) {
            // axios.get(API_URL.AGGREGATE_COMPARING_CHARTS, { params: { id: patientId, cultureInfo: locale } }).then((historic_data) => {
            dispatch(setAggregateGraph(biomarkers_historic_data))
            dispatch(setComparingChartsData(biomarkers_historic_data))

            let data = biomarkers_historic_data;
            let marqueeBioCount = 0;
            if (data) {
                for (let prop in data) {
                    if (Array.isArray(data[prop])) {
                        if (data[prop].length > 0)
                            marqueeBioCount++;
                    }
                }
            }
            dispatch({
                type: SET_PROPS,
                payload: {
                    marqueeBioCount: marqueeBioCount
                }
            })

            // }).catch((error) => {
            //     dispatch({
            //         type: SET_PROPS,
            //         payload: {
            //             loadingPatient: constants.notification_type.error
            //         }
            //     })
            //     dispatch(handleErrors(error))
            // })
        }
        // else {
        //     let currentFollowUpCount = !isNaN(getState().analytics.currentTimepoint) ? getState().analytics.currentTimepoint : 0
        //     let query = `followUpCount=${currentFollowUpCount}&cultureInfo=${locale}&visitId=${summaryVisitId}`
        //     axios.get(`${API_URL.FOLLOW_UP_DATA}/${patientId}?${query}`).then((response) => {
        //         let followupdata = response.data.filter(d => d.visitId == summaryVisitId && d.value && parseInt(d.value))

        //         let historic_data = {}

        //         Object.keys(constants.biographical_labels.followup_ages).map(fp => {
        //             historic_data[fp] = followupdata.filter(d => d.label == constants.biographical_labels.followup_ages[fp] && d.visitId == summaryVisitId)

        //         })
        //         dispatch(setComparingChartsData(historic_data))
        //     }).catch(errors => {
        //         dispatch(handleErrors(errors));
        //     })
        // }
    }
}


export function saveRecommendationNotes(notes) {
    return (dispatch, getState) => {
        let recommendationForm = { ...getState().analytics.recommendationForm, notes }
        dispatch({
            type: SET_PROPS,
            payload: {
                recommendationForm
            }
        })
    }
}
export function saveMetaRecommendationNotes(notes) {
    return (dispatch, getState) => {
        let metaRecommendationForm = { ...getState().analytics.metaRecommendationForm, notes }
        dispatch({
            type: SET_PROPS,
            payload: {
                metaRecommendationForm
            }
        })
    }
}
export function editRecommendation(event, recId) {
    return (dispatch, getState) => {
        let recommendationForm = { ...getState().analytics.recommendationForm }
        let brand_list = getState().analytics.brand_list ? getState().analytics.brand_list : []
        let metaRecommendationForm = { ...getState().analytics.metaRecommendationForm }
        let recommendation_list = getState().analytics.recommendation_list ? getState().analytics.recommendation_list : []
        let strength_list = getState().analytics.strength_list ? getState().analytics.strength_list : []
        let instruction_list = getState().analytics.instruction_list ? getState().analytics.instruction_list : []
        let notes_list = getState().analytics.notes_list ? getState().analytics.notes_list : []
        let all_recommendations_data = getState().analytics.all_recommendations_data ? getState().analytics.all_recommendations_data : []
        let recommendationsGroupDTO = all_recommendations_data && all_recommendations_data.recommendationsGroupDTO ? all_recommendations_data.recommendationsGroupDTO : []
        let recommendationSigsDTO = all_recommendations_data && all_recommendations_data.recommendationSigsDTO ? all_recommendations_data.recommendationSigsDTO : []
        let recommedationDTO;
        let recommendation_list_option;
        let recommendationStrength;
        let brand_names = []
        let brand_name_list = []

        if (recId == 'brand') {
            if (event != null && getState().analytics.recommendationForm && getState().analytics.recommendationForm.brand != event.value) {
                recommendationForm = { ...getState().analytics.recommendationForm, brand: event.value, body: "", strength: '', recommendationStrengthType: '', instructions: '', notes: '', productType: '' }
                if (recommendationsGroupDTO) {
                    if (recommendationForm.brand) {
                        recommendation_list = recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(getState().analytics.recommendationForm.group))
                            .filter((recommendation) => recommendation.brandNames.includes(recommendationForm.brand))
                            .map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom } })
                    }
                    else {
                        recommendation_list = recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(recommendationForm.group)).map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom } })
                    }
                }
                else {
                    recommendation_list = []
                }
            }
        }
        else if (recId == 'body') {
            if (event != null && getState().analytics.recommendationForm && getState().analytics.recommendationForm.body != event.value) {
                recommendationForm = { ...getState().analytics.recommendationForm, body: event.value, strength: '', recommendationStrengthType: '', instructions: '', notes: '' }
                if (recommendationsGroupDTO && event.value && recommendation_list) {
                    recommendation_list_option = recommendation_list.find((r) => r.value == event.value)
                    
                    if (recommendation_list_option && !recommendation_list_option.isCustom ) {
                        recommedationDTO = recommendationsGroupDTO.find((rec) => rec.id == recommendation_list_option.id)
                        recommendationForm = { ...recommendationForm, productType: recommedationDTO.productType}
                    }

                    else if( recommendation_list_option && recommendation_list_option.isCustom) {
                        recommedationDTO = recommendationsGroupDTO.find((rec) => rec.body == recommendation_list_option.value && rec.groupNames.includes(recommendationForm.group) )
                        if (recommendationForm.brand != ""){
                            recommedationDTO = recommendationsGroupDTO.find((rec) => rec.body == recommendation_list_option.value && rec.groupNames.includes(recommendationForm.group) && rec.brandNames.includes(recommendationForm.brand) )
                        }
                    }
                    else
                        strength_list = []    
                    if (recommedationDTO && recommendationForm.productType && recommendationSigsDTO.find((sigs) => sigs.recommendationForm == recommendationForm.productType)){
                        let sigs = [];
                        sigs = recommendationSigsDTO.filter((sig) => sig.recommendationForm == recommendationForm.productType)
                        instruction_list = sigs.map((sig) => sig.recommendationSigs.map((s)=> { return { value: s } })).flat()
                    }
                    else if(recommedationDTO && !recommendationForm.productType && recommendationSigsDTO.find((sigs) => sigs.recommendationForm == "")){
                        let sigs = [];
                        sigs = recommendationSigsDTO.filter((sig) => sig.recommendationForm == "")
                        instruction_list = sigs.map((sig) => sig.recommendationSigs.map((s)=> { return { value: s } })).flat()
                    }    
                    else
                        instruction_list = []
                    if (recommedationDTO && recommedationDTO.recommendationStrengths)
                        strength_list = recommedationDTO.recommendationStrengths.map((rec) => { return { id: rec.strengthId, value: rec.strength } })
                    else
                        strength_list = []
                }
                else {
                    strength_list = []
                }
            }
        }

        else if (recId == 'strength') {
            if (event != null) {
                if (metaRecommendationForm && getState().analytics.isMetaRecommendation) {
                    metaRecommendationForm = { ...getState().analytics.metaRecommendationForm, strength: event.value }
                }
                else {
                    let initialRecommendationForm = getState().analytics.initialRecommendationForm;
                    let instructionAndNotes = setInstructionsAndNotes(initialRecommendationForm, event.value)
                    recommendationForm = { ...getState().analytics.recommendationForm, strength: event.value, recommendationStrengthType: '', instructions: instructionAndNotes.instructions, notes: instructionAndNotes.notes }
                }
                if (recommendationsGroupDTO && event.value && recommendation_list) {
                    let recommendation = (metaRecommendationForm && getState().analytics.isMetaRecommendation) ? metaRecommendationForm.body : recommendationForm.body
                    recommendation_list_option = recommendation_list.find((r) => r.value == recommendation)

                    if (recommendation_list_option && recommendation_list_option.id) {
                        recommedationDTO = recommendationsGroupDTO.find((rec) => rec.id == recommendation_list_option.id)
                    }
                    else {
                        notes_list = []
                    }
                    if (recommedationDTO && recommedationDTO.recommendationStrengths) {
                        recommendationStrength = recommedationDTO.recommendationStrengths.find((rec) => rec.recommendationId == recommendation_list_option.id
                            && event.id ? event.id == rec.strengthId : event.value == rec.strength)
                    }
                    else {
                        notes_list = []
                    }
                    if (recommendationStrength && recommendationStrength.recommendationInstructions){
                        Array.prototype.push.apply(instruction_list, recommendationStrength.recommendationInstructions.map((rec) => { return { id: rec.id, value: rec.instruction } }))
                    }

                    if (recommendationStrength && recommendationStrength.recommendationNotes)
                        notes_list = recommendationStrength.recommendationNotes.map((rec) => { return { id: rec.id, value: rec.note } })
                    else
                        notes_list = []
                }
                else {
                    notes_list = []
                }
            }
        }

        else if (recId == 'recommendationStrengthType') {
            if (event != null) {
                if (metaRecommendationForm && getState().analytics.isMetaRecommendation) {
                    metaRecommendationForm = { ...getState().analytics.metaRecommendationForm, recommendationStrengthType: event.target.value }
                }
                else {
                    recommendationForm = { ...getState().analytics.recommendationForm, recommendationStrengthType: event.target.value }
                }
            }
        }
        else if (recId == 'instruction') {
            if (event != null) {
                if (metaRecommendationForm && getState().analytics.isMetaRecommendation) {
                    metaRecommendationForm = { ...getState().analytics.metaRecommendationForm, instructions: event.value }
                }
                else {
                    recommendationForm = { ...getState().analytics.recommendationForm, instructions: event.value }
                }
            }
        }
        else if (recId == 'notes') {
            if (event != null) {
                if (metaRecommendationForm && getState().analytics.isMetaRecommendation) {
                    metaRecommendationForm = { ...getState().analytics.metaRecommendationForm, notes: event.value }
                }
                else {
                    recommendationForm = { ...getState().analytics.recommendationForm, notes: event.value }
                }
            }
        }
        else if (recId == 'startedAt') {
            if (metaRecommendationForm && getState().analytics.isMetaRecommendation) {
                metaRecommendationForm = { ...getState().analytics.metaRecommendationForm, startedAt: event }
            }
            else {
                recommendationForm = { ...getState().analytics.recommendationForm, startedAt: event }
            }
        }

        else if (recId == 'endedAt') {
            if (metaRecommendationForm && getState().analytics.isMetaRecommendation) {
                metaRecommendationForm = { ...getState().analytics.metaRecommendationForm, endedAt: event }
            }
            else {
                recommendationForm = { ...getState().analytics.recommendationForm, endedAt: event }
            }
        }
        else {
            let key = event.target.id
            let value = event.target.value
            if (getState().analytics.recommendationForm && getState().analytics.recommendationForm[key] != value) {
                recommendationForm = getState().analytics.recommendationForm ? { ...getState().analytics.recommendationForm, [key]: value, brand: '', body: '', strength: '', recommendationStrengthType: '0', instructions: '', notes: '', productType: '' } : { [key]: value, body: '', strength: '', recommendationStrengthType: '0', instructions: '', notes: '' }
            }
            else {
                recommendationForm = getState().analytics.recommendationForm
            }
            if (recommendationsGroupDTO) {
                recommendation_list = recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(value)).map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom} })
            }
            else {
                recommendation_list = []
            }
            if (recommendation_list) {
                recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(value)).map((rec) => brand_names.push(rec.brandNames))
                brand_name_list = [...new Set(brand_names.flat())]
                if (brand_name_list)
                    brand_list = brand_name_list.map((brand) => { return { value: brand } })
                else {
                    brand_list = []
                }
            }
            else {
                brand_list = []
            }
            strength_list = []
            instruction_list = []
            notes_list = []
        }
        dispatch(showNoFormChangesError(false));
        dispatch({
            type: SET_PROPS,
            payload: {
                recommendationForm,
                brand_list,
                recommendation_list: recommendation_list,
                strength_list: strength_list,
                instruction_list: instruction_list,
                notes_list: notes_list,
                recommendationErrors: [],
                metaRecommendationForm
            }
        })
    }
}

export function editMedication(event, recId) {
    return (dispatch, getState) => {
        let medicationForm = { ...getState().analytics.medicationForm }
        let brand_list = getState().analytics.brand_list ? getState().analytics.brand_list : []
        let recommendation_list = getState().analytics.recommendation_list ? getState().analytics.recommendation_list : []
        let strength_list = getState().analytics.strength_list ? getState().analytics.strength_list : []
        let instruction_list = getState().analytics.instruction_list ? getState().analytics.instruction_list : []
        let notes_list = getState().analytics.notes_list ? getState().analytics.notes_list : []
        let all_recommendations_data = getState().analytics.all_recommendations_data ? getState().analytics.all_recommendations_data : []
        let recommendationsGroupDTO = all_recommendations_data && all_recommendations_data.recommendationsGroupDTO ? all_recommendations_data.recommendationsGroupDTO : []
        let recommendationSigsDTO = all_recommendations_data && all_recommendations_data.recommendationSigsDTO ? all_recommendations_data.recommendationSigsDTO : []
        let recommedationDTO;
        let recommendation_list_option;
        let recommendationStrength;
        let brand_names = []
        let brand_name_list = []
        if (recId == 'brand') {

            if (event != null && getState().analytics.medicationForm && getState().analytics.medicationForm.brand != event.value) {
                medicationForm = { ...getState().analytics.medicationForm, brand: event.value, body: "", strength: '', recommendationStrengthType: '', instructions: '', notes: '' }
                if (recommendationsGroupDTO) {
                    if (medicationForm.brand) {
                        recommendation_list = recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(getState().analytics.medicationForm.group))
                            .filter((recommendation) => recommendation.brandNames.includes(medicationForm.brand))
                            .map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom} })
                    }
                    else {
                        recommendation_list = recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(medicationForm.group)).map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom} })
                    }
                }
                else {
                    recommendation_list = []
                }

            }
        }
        else if (recId == 'body') {
            if (event != null && getState().analytics.medicationForm && getState().analytics.medicationForm.body != event.value) {
                medicationForm = { ...getState().analytics.medicationForm, body: event.value, strength: '', recommendationStrengthType: '', instructions: '', notes: '' }
                if (recommendationsGroupDTO && event.value && recommendation_list) {
                    recommendation_list_option = recommendation_list.find((r) => r.value == event.value)
                    
                    if (recommendation_list_option && !recommendation_list_option.isCustom ) {
                        recommedationDTO = recommendationsGroupDTO.find((rec) => rec.id == recommendation_list_option.id)
                        medicationForm = { ...medicationForm, productType: recommedationDTO.productType}
                    }

                    else if( recommendation_list_option && recommendation_list_option.isCustom) {
                        recommedationDTO = recommendationsGroupDTO.find((rec) => rec.body == recommendation_list_option.value && rec.groupNames.includes(medicationForm.group) )
                        if (medicationForm.brand != ""){
                            recommedationDTO = recommendationsGroupDTO.find((rec) => rec.body == recommendation_list_option.value && rec.groupNames.includes(medicationForm.group) && rec.brandNames.includes(medicationForm.brand) )
                        }
                    }
                    else
                        strength_list = []    
                    if (recommedationDTO && medicationForm.productType && recommendationSigsDTO.find((sigs) => sigs.recommendationForm == medicationForm.productType)){
                        let sigs = [];
                        sigs = recommendationSigsDTO.filter((sig) => sig.recommendationForm == medicationForm.productType)
                        instruction_list = sigs.map((sig) => sig.recommendationSigs.map((s)=> { return { value: s } })).flat()
                    }
                    else if(recommedationDTO && !medicationForm.productType && recommendationSigsDTO.find((sigs) => sigs.recommendationForm == "")){
                        let sigs = [];
                        sigs = recommendationSigsDTO.filter((sig) => sig.recommendationForm == "")
                        instruction_list = sigs.map((sig) => sig.recommendationSigs.map((s)=> { return { value: s } })).flat()
                    }    
                    else
                        instruction_list = []
                    if (recommedationDTO && recommedationDTO.recommendationStrengths)
                        strength_list = recommedationDTO.recommendationStrengths.map((rec) => { return { id: rec.strengthId, value: rec.strength } })
                    else
                        strength_list = []
                }
                else {
                    strength_list = []
                }
            }
        }
        else if (recId == 'strength') {
            if (event != null) {
                let initialMedicationForm = getState().analytics.initialMedicationForm;
                let instructionAndNotes = setInstructionsAndNotes(initialMedicationForm, event.value)
                medicationForm = { ...getState().analytics.medicationForm, strength: event.value, recommendationStrengthType: '', instructions: instructionAndNotes.instructions, notes: instructionAndNotes.notes }
                if (recommendationsGroupDTO && event.value && recommendation_list) {
                    recommendation_list_option = recommendation_list.find((r) => r.value == medicationForm.body)
                    if (recommendation_list_option && recommendation_list_option.id) {
                        recommedationDTO = recommendationsGroupDTO.find((rec) => rec.id == recommendation_list_option.id)
                    }
                    else {
                        notes_list = []
                    }
                    if (recommedationDTO && recommedationDTO.recommendationStrengths) {
                        recommendationStrength = recommedationDTO.recommendationStrengths.find((rec) => rec.recommendationId == recommendation_list_option.id
                            && event.id ? event.id == rec.strengthId : event.value == rec.strength)
                    }
                    else {
                        notes_list = []
                    }
                    if (recommendationStrength && recommendationStrength.recommendationInstructions){
                        Array.prototype.push.apply(instruction_list, recommendationStrength.recommendationInstructions.map((rec) => { return { id: rec.id, value: rec.instruction } }))
                    }

                    if (recommendationStrength && recommendationStrength.recommendationNotes)
                        notes_list = recommendationStrength.recommendationNotes.map((rec) => { return { id: rec.id, value: rec.note } })
                    else
                        notes_list = []
                }
                else {
                    notes_list = []
                }
            }
        }

        else if (recId == 'recommendationStrengthType') {
            if (event != null) {
                medicationForm = { ...getState().analytics.medicationForm, recommendationStrengthType: event.target.value }
            }
        }

        else if (recId == 'instruction') {
            if (event != null) {
                medicationForm = { ...getState().analytics.medicationForm, instructions: event.value }
            }
        }
        else if (recId == 'notes') {
            if (event != null) {
                medicationForm = { ...getState().analytics.medicationForm, notes: event.value }
            }
        }
        else if (recId == 'startedAt') {
            medicationForm = { ...getState().analytics.medicationForm, startedAt: event }
        }

        else if (recId == 'endedAt') {
            medicationForm = { ...getState().analytics.medicationForm, endedAt: event }
        }
        else {
            let key = event.target.id
            let value = event.target.value
            if (getState().analytics.medicationForm && getState().analytics.medicationForm[key] != value) {
                medicationForm = getState().analytics.medicationForm ? { ...getState().analytics.medicationForm, [key]: value, brand: '', body: '', strength: '', recommendationStrengthType: '', instructions: '', notes: '' } : { [key]: value, body: '', strength: '', recommendationStrengthType: '', instructions: '', notes: '' }
            }
            else {
                medicationForm = getState().analytics.medicationForm
            }
            if (recommendationsGroupDTO) {
                recommendation_list = recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(value)).map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom} })
            }
            else {
                recommendation_list = []
            }

            if (recommendation_list) {
                recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(value)).map((rec) => brand_names.push(rec.brandNames))
                brand_name_list = [...new Set(brand_names.flat())]
                if (brand_name_list)
                    brand_list = brand_name_list.map((brand) => { return { value: brand } })
                else {
                    brand_list = []
                }
            }
            else {
                brand_list = []
            }
            strength_list = []
            instruction_list = []
            notes_list = []
        }
        dispatch(showNoMedicationChangesError(false));
        dispatch({
            type: SET_PROPS,
            payload: {
                medicationForm,
                brand_list,
                recommendation_list: [...new Map(recommendation_list.map(item => [JSON.stringify(item), item])).values()],
                instruction_list: [...new Map(instruction_list.map(item => [JSON.stringify(item), item])).values()],
                strength_list: [...new Map(strength_list.map(item => [JSON.stringify(item), item])).values()],
                notes_list: [...new Map(notes_list.map(item => [JSON.stringify(item), item])).values()],
                recommendationErrors: []
            }
        })
    }
}
function setInstructionsAndNotes(initialForm, value) {
    let instructions;
    let notes;
    if (value == "" && initialForm && initialForm.strength && initialForm.strength == "" && initialForm.instructions) {
        instructions = initialForm.instructions
    }
    else {
        instructions = ""
    }
    if (value == "" && initialForm && initialForm.strength && initialForm.strength == "" && initialForm.notes) {
        notes = initialForm.notes
    }
    else {
        notes = ""
    }
    return { instructions, notes }
}

export function saveMedication(history, isPlanModal, metaRec) {
    return (dispatch, getState) => {
        let recommendations = getState().analytics;
        let medicationForm = recommendations.medicationForm

        let groupName = 'Supplement'
        let demoPath = window.location.pathname.indexOf('demo_1') == 1 ? 'demo_1' : 'demo_2';
        dispatch({
            type: SET_PROPS,
            payload: {
                isRecommendationsLoading: true,
                medicationForm: ''
            }
        })
        var currentRecommendation = null;
        currentRecommendation = {
            providerId: recommendations.presentPatient.providerId,
            visitid: recommendations.presentPatient.latestVisitId,
            ...medicationForm,
            indicators: {
                recommendationId: recommendations && medicationForm ? medicationForm.id : 0,
                code: isPlanModal ? '' : ((recommendations && recommendations.datumValues) ? recommendations.datumValues.code : '')
            }
        }

        currentRecommendation['recommendationId'] = recommendations && medicationForm ? medicationForm.id : 0
        currentRecommendation['isStopped'] = medicationForm.isStopped ? medicationForm.isStopped : 0
        currentRecommendation['priorVisitId'] = recommendations.presentPatient.priorVisitId
        currentRecommendation['patientId'] = recommendations.presentPatient.id
        currentRecommendation['visitId'] = recommendations.presentPatient.latestVisitId
        currentRecommendation['endedAt'] = currentRecommendation.endedAt ? moment(currentRecommendation.endedAt).format('YYYY-MM-DD HH:mm:ss') : null
        currentRecommendation['startedAt'] = currentRecommendation.startedAt ? moment(currentRecommendation.startedAt).format('YYYY-MM-DD HH:mm:ss') : null
        currentRecommendation['isCustomRecommendation'] = getState().analytics.recommendation_list && getState().analytics.recommendation_list.find((r) => r.value == medicationForm.body) || (medicationForm.body == null || medicationForm.body == "") ?  'false' : 'true' 
        currentRecommendation['IsCustomSig'] = getState().analytics.instruction_list && getState().analytics.instruction_list.find((r) => r.value == medicationForm.instructions) || (medicationForm.instructions == null || medicationForm.instructions == "") ?  'false' : 'true' 
        currentRecommendation['siteId'] = recommendations.presentPatient.siteId

        let req = medicationRequiredFields.filter(rf => (!currentRecommendation[rf] || parseInt(currentRecommendation['group']) == 0))
        if (req && req.length) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    medicationErrors: req.map(r => `${medicationRequiredFieldText[r]} ${labels.required_label}`),
                    isRecommendationsLoading: false,
                    medicationForm: { group: currentRecommendation.group, body: currentRecommendation.body, instructions: currentRecommendation.instructions, notes: currentRecommendation.notes, strength: currentRecommendation.strength, recommendationStrengthType: currentRecommendation.recommendationStrengthType, startedAt: currentRecommendation.startedAt, endedAt: currentRecommendation.endedAt, },
                }
            })
        }
        else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    medicationErrors: [],
                    groupName: groupName
                }
            })
            let isDemo = getState().analytics.isDemo
            if (isPlanModal)
                dispatch(toggleMedicationModal(false))
            if (!isDemo) {
                axios[currentRecommendation.id ? 'put' : 'post'](API_URL.RECOMMENDATION_URL, { ...currentRecommendation }).then((response) => {
                    dispatch(getRecommendations())
                    if (isPlanModal)
                        dispatch(toggleMedicationModal(false))
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            initialMedicationForm: { group: groupName },
                            resumeRecommendation: false,
                            recommendationCurrentRow: null,
                            recommendation_list: setRecommendationList(getState().analytics, groupName),
                            brand_list: setBrandList(getState().analytics, groupName)
                        }
                    })
                    dispatch(showNoMedicationChangesError(false));
                }).catch(errors => {
                    let recError = errors.response && errors.response.data ? errors.response.data : ''
                    if (errors.response && errors.response.status == constants.status_codes.unauthorized)
                        dispatch(handleErrors(errors))
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            medicationErrors: recError ? typeof (recError) == 'string' ? [recError] : recError.value ? [recError.value] : null : null,
                            isRecommendationsLoading: false,
                            medicationForm: { group: groupName, body: null },
                            initialMedicationForm: { group: groupName },
                            resumeRecommendation: false,
                            recommendationCurrentRow: null
                        }
                    })
                    dispatch(showNoMedicationChangesError(false));
                    if (metaRec) {
                        dispatch({
                            type: SET_PROPS,
                            payload: {
                                medicationErrors: []
                            }
                        })
                    }
                    if (isPlanModal)
                        dispatch(toggleMedicationModal(true))
                })
            } else {
                let allRecommendations = getState().analytics.recommendations
                    ? getState().analytics.recommendations : demoData.recommendationsInfo[demoPath]

                currentRecommendation = {
                    ...currentRecommendation,
                    indicators: getState().analytics.drawerType == "biomarker" ? [recommendations.ages]
                        : (getState().analytics.drawerType == "category" ? [recommendations ? recommendations.currentCategory.key : ''] : (getState().analytics.drawerType == "section" ? [recommendations ? recommendations.currentSection.key : ''] : [recommendations.datumValues ? recommendations.datumValues.code : '']))
                }
                let finalRec = []
                allRecommendations.map(rec => {
                    if (rec.id && rec.id == currentRecommendation.id)
                        finalRec.push(currentRecommendation)
                    else finalRec.push(rec)
                })
                if (!currentRecommendation.id && finalRec.filter(s => s.id === undefined && (_.head(s.indicators) == _.head(currentRecommendation.indicators))).length < 200) {
                    currentRecommendation = {
                        ...currentRecommendation,
                        id: Math.floor((Math.random() * 1000000) + 1)
                    }
                    finalRec.push(currentRecommendation)
                }

                if (isPlanModal)
                    dispatch(toggleMedicationModal(false))
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        isRecommendationsLoading: false,
                        medicationForm: { group: groupName, body: null },
                        currentRecommendation: null,
                        // recommendations: finalRec,
                        recommendations: finalRec && finalRec.length ? finalRec.filter(s => s.isStopped != 1) : [],
                        medications: finalRec && finalRec.length ? finalRec.filter((rec) => constants.medication_groups.includes(rec.group)) : [],
                        initialMedicationForm: { group: groupName },
                        recommendationCurrentRow: null,
                        recommendation_list: setRecommendationList(getState().analytics, groupName),
                        brand_list: setBrandList(getState().analytics, groupName)
                    }
                })
            }
        }
    }
}

export function updateMedication(id, recomm) {
    return (dispatch, getState) => {
        let updateRecommendation = getState().analytics;
        let medicationForm = {};
        if (recomm == 'resumeCurrent' && updateRecommendation.stoppedRecommendations)
            medicationForm = _.head(updateRecommendation.stoppedRecommendations.filter(rec => rec.id == id));
        else if (recomm == 'resumeHistoric' && updateRecommendation.filteredRecommendations)
            medicationForm = _.head(updateRecommendation.filteredRecommendations.filter(rec => rec.id == id));
        else
            medicationForm = _.head(updateRecommendation.recommendations.filter(rec => rec.id == id));

        if (recomm) {
            medicationForm.isStopped = recomm == 'stop' ? 1 : 0
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                medicationForm,
                brand_list,
                recommendation_list,
                instruction_list,
                notes_list,
                strength_list
            }
        })
        let brand_names = []
        let brand_name_list = []
        dispatch(showNoMedicationChangesError(false));
        let all_recommendations_data = getState().analytics.all_recommendations_data ? getState().analytics.all_recommendations_data : []
        let recommendationsGroupDTO = all_recommendations_data && all_recommendations_data.recommendationsGroupDTO ? all_recommendations_data.recommendationsGroupDTO : []
        let recommendationSigsDTO = all_recommendations_data && all_recommendations_data.recommendationSigsDTO ? all_recommendations_data.recommendationSigsDTO : []       
        let brand_list = getState().analytics.brand_list ? getState().analytics.brand_list : []
        // let recommendation_list = getState().analytics.recommendation_list ? getState().analytics.recommendation_list : []
        let strength_list = getState().analytics.strength_list ? getState().analytics.strength_list : []
        let instruction_list = getState().analytics.instruction_list ? getState().analytics.instruction_list : []
        let notes_list = getState().analytics.notes_list ? getState().analytics.notes_list : []
        let recommedationDTO;
        let group = getState().analytics && getState().analytics.medicationForm && getState().analytics.medicationForm.group ? getState().analytics.medicationForm.group : 'General Instructions';
        let brand = getState().analytics && getState().analytics.medicationForm && getState().analytics.medicationForm.brand ? getState().analytics.medicationForm.brand : '';
        let body = getState().analytics && getState().analytics.medicationForm && getState().analytics.medicationForm.body ? getState().analytics.medicationForm.body : "";
        let strength = getState().analytics && getState().analytics.medicationForm && getState().analytics.medicationForm.strength ? getState().analytics.medicationForm.strength : "";
        let recommendationStrengthType = getState().analytics && getState().analytics.medicationForm && getState().analytics.medicationForm.recommendationStrengthType ? getState().analytics.medicationForm.recommendationStrengthType : "";
        let instructions = getState().analytics && getState().analytics.medicationForm && getState().analytics.medicationForm.instructions ? getState().analytics.medicationForm.instructions : "";
        let notes = getState().analytics && getState().analytics.medicationForm && getState().analytics.medicationForm.notes ? getState().analytics.medicationForm.notes : "";
        let startedAt = getState().analytics && getState().analytics.medicationForm && getState().analytics.medicationForm.startedAt ? getState().analytics.medicationForm.startedAt : "";
        let endedAt = getState().analytics && getState().analytics.medicationForm && getState().analytics.medicationForm.endedAt ? getState().analytics.medicationForm.endedAt : "";
        let recommendation_list_option;
        let recommendationStrength;
        let recommendation_list = setRecommendationList(getState().analytics, group)
 
        if (group) {
            if (brand) {
                recommendation_list = recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(group))
                    .filter((recommendation) => recommendation.brandNames.includes(brand))
                    .map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom } })
            }
            else {
                recommendation_list = recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(group)).map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom } })
            }
        }

        if (recommendation_list) {
            recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(group)).map((rec) => brand_names.push(rec.brandNames))
            brand_name_list = [...new Set(brand_names.flat())]
            if (brand_name_list)
                brand_list = brand_name_list.map((brand) => { return { value: brand } })
            else {
                brand_list = []
            }
        }
        else {
            brand_list = []
        }

        if (group && body && recommendation_list) {
            recommendation_list_option = recommendation_list.find((r) => r.value == body)
            if (recommendation_list_option && recommendation_list_option.id) {
                recommedationDTO = recommendationsGroupDTO.find((rec) => rec.id == recommendation_list_option.id)
            }
            else
                strength_list = []

            if (recommedationDTO && recommedationDTO.recommendationStrengths) {
                strength_list = recommedationDTO.recommendationStrengths.map((rec) => { return { id: rec.strengthId, value: rec.strength } })
                recommendationStrength = recommedationDTO.recommendationStrengths.find((rec) => rec.recommendationId == recommendation_list_option.id && getState().analytics.medicationForm.strength == rec.strength)
            }
            if (recommedationDTO){
                medicationForm = { ...medicationForm, productType: recommedationDTO.productType}
            }

            if (recommedationDTO && medicationForm.productType && recommendationSigsDTO.find((sigs) => sigs.recommendationForm == medicationForm.productType)){
                let sigs = [];
                sigs = recommendationSigsDTO.filter((sig) => sig.recommendationForm == medicationForm.productType)
                instruction_list = sigs.map((sig) => sig.recommendationSigs.map((s)=> { return { value: s } })).flat()
            }
            else if(recommedationDTO && !medicationForm.productType && recommendationSigsDTO.find((sigs) => sigs.recommendationForm == "")){
                let sigs = [];
                sigs = recommendationSigsDTO.filter((sig) => sig.recommendationForm == "")
                instruction_list = sigs.map((sig) => sig.recommendationSigs.map((s)=> { return { value: s } })).flat()
            }
            else {
                strength_list = []
                instruction_list = []
                notes_list = []
            }

            if (recommendationStrength && recommendationStrength.recommendationInstructions)
                Array.prototype.push.apply(instruction_list, recommendationStrength.recommendationInstructions.map((rec) => { return { id: rec.id, value: rec.instruction } }))    
            if (recommendationStrength && recommendationStrength.recommendationNotes)
                notes_list = recommendationStrength.recommendationNotes.map((rec) => { return { id: rec.id, value: rec.note } })
            else
                notes_list = []
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                recommendation_list,
                brand_list,
                strength_list,
                instruction_list,
                notes_list,
                medicationForm,
            }
        })
        if (updateRecommendation && updateRecommendation.recommendationCurrentRow) {
            group = updateRecommendation.recommendationCurrentRow.group ? updateRecommendation.recommendationCurrentRow.group : 'Supplement';
            brand = updateRecommendation.recommendationCurrentRow.brand ? updateRecommendation.recommendationCurrentRow.brand : '';
            body = updateRecommendation.recommendationCurrentRow.body ? updateRecommendation.recommendationCurrentRow.body : "";
            instructions = updateRecommendation.recommendationCurrentRow.instructions ? updateRecommendation.recommendationCurrentRow.instructions : "";
            notes = updateRecommendation.recommendationCurrentRow.notes ? updateRecommendation.recommendationCurrentRow.notes : "";
            strength = getState().analytics && getState().analytics.medicationForm && getState().analytics.medicationForm.strength ? getState().analytics.medicationForm.strength : "";
            recommendationStrengthType = getState().analytics && getState().analytics.medicationForm && getState().analytics.medicationForm.recommendationStrengthType ? getState().analytics.medicationForm.recommendationStrengthType : "";
            startedAt = getState().analytics && getState().analytics.medicationForm && getState().analytics.medicationForm.startedAt ? getState().analytics.medicationForm.startedAt : "";
            endedAt = getState().analytics && getState().analytics.medicationForm && getState().analytics.medicationForm.endedAt ? getState().analytics.medicationForm.endedAt : "";
            medicationForm = { ...medicationForm, group: group, brand: brand, body: body, strength: strength, instructions: instructions, notes: notes, startedAt: startedAt, endedAt: endedAt };
            dispatch({
                type: SET_PROPS,
                payload: {
                    medicationForm
                }
            })
        }

        dispatch(setInitialMedicationForm(group, brand, body, strength, recommendationStrengthType, instructions, notes, startedAt, endedAt, medicationForm.productType));        
        if (recomm) {
            dispatch(saveMedication())
        }
    }
}

export function setInitialMedicationForm(group, brand, body, strength, recommendationStrengthType, instructions, notes, startedAt, endedAt, productType) {
    return (dispatch, getState) => {
        let initialMedicationForm = { group: group, brand: brand, body: body, strength: strength, recommendationStrengthType: recommendationStrengthType, instructions: instructions, notes: notes, startedAt: startedAt, endedAt: endedAt, productType: productType }
        dispatch({
            type: SET_PROPS,
            payload: {
                initialMedicationForm
            }
        })
    }
}

export function showNoMedicationChangesError(val) {
    return (dispatch, getState) => {
        let medicationErrors = getState().analytics.medicationErrors ? [...getState().analytics.medicationErrors] : [];
        if (val)
            medicationErrors.push(labels.no_medication_changes);
        else {
            let errIndex = medicationErrors.indexOf(labels.no_medication_changes);
            medicationErrors.splice(errIndex, 1);
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                medicationErrors: medicationErrors
            }
        })
    }
}

export function getMedication() {
    return (dispatch, getState) => {
        let recommendations = getState().analytics && getState().analytics.recommendations ? getState().analytics.recommendations : []
        let stoppedRecommendations = getState().analytics && getState().analytics.stoppedRecommendations ? getState().analytics.stoppedRecommendations : []
        let medications = recommendations ? recommendations.filter((rec) => constants.medication_groups.includes(rec.group)) : []
        let stoppedMedications = stoppedRecommendations ? stoppedRecommendations.filter((rec) => constants.medication_groups.includes(rec.group)) : []

        dispatch({
            type: SET_PROPS,
            payload: {
                medications: medications && medications.length ? medications.filter(s => s.isStopped != 1) : [],
                stoppedMedications: stoppedMedications,
            }
        })
    }

}

export function deleteMedication(id, code) {
    return (dispatch, getState) => {
        let groupName = 'Supplement'
        dispatch({
            type: SET_PROPS,
            payload: {
                isRecommendationsLoading: true,
                medicationForm: { group: groupName, notes: null, body: null, strength: null, recommendationStrengthType: null, startedAt: null, endedAt: null },
                groupName: groupName,
                recommendation_list: setRecommendationList(getState().analytics, groupName),
                brand_list: setBrandList(getState().analytics, groupName)
            }
        })
        let isDemo = getState().analytics.isDemo
        if (!isDemo) {
            let finalDatumResult = getState().analytics.datumValues ? getState().analytics.datumValues.code : ''
            let resCode = code ? code : getState().analytics.ages;
            let datumDrawer = code ? code : finalDatumResult;
            if (resCode && getState().analytics.drawerType == 'biomarker') {
                axios.delete(`${API_URL.RECOMMENDATION_URL}/${id}?code=${resCode}`).then(response => {
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            isRecommendationsLoading: false,
                        }
                    })
                    dispatch(getRecommendations());
                })
            }
            else {
                axios.delete(`${API_URL.RECOMMENDATION_URL}/${id}?code=${datumDrawer}`).then(response => {
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            isRecommendationsLoading: false,
                        }
                    })
                    dispatch(getRecommendations());
                })
            }
        } else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    isRecommendationsLoading: false,
                    medicationForm: { group: groupName },
                    currentRecommendation: null,
                    recommendation_list: setRecommendationList(getState().analytics, groupName),
                    brand_list: setBrandList(getState().analytics, groupName),
                    recommendations: getState().analytics.recommendations.filter(obj => {
                        if (!code) {
                            if ((obj.id && obj.id != id) || (!obj.id && obj.id != id)) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                        else if (obj.indicators.length && _.head(obj.indicators).toLowerCase() == code.toLowerCase()) {
                            if ((obj.id && obj.id != id) || (!obj.id && obj.id != id)) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                        else {
                            return true;
                        }

                    })
                }
            })
        }
    }
}

export function toggleStopMedicationPopup(value, stopRecommendId, resumeRecommendation, recommendationCurrentRow) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                stopMedicationPopup: value,
                stopRecommendId: stopRecommendId,
            }
        })
        if (recommendationCurrentRow) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendationCurrentRow: { ...recommendationCurrentRow }
                }
            })
        }
        if (!value) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendationCurrentRow: null
                }
            })
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                resumeRecommendation: resumeRecommendation ? resumeRecommendation : false
            }
        })

    }
}

export function updateRecommendations(id, recomm) {
    return (dispatch, getState) => {
        let updateRecommendation = getState().analytics;
        let recommendationForm = {};
        if (recomm == 'resumeCurrent' && updateRecommendation.stoppedRecommendations)
            recommendationForm = _.head(updateRecommendation.stoppedRecommendations.filter(rec => rec.id == id));
        else if (recomm == 'resumeHistoric' && updateRecommendation.filteredRecommendations)
            recommendationForm = _.head(updateRecommendation.filteredRecommendations.filter(rec => rec.id == id));
        else
            recommendationForm = _.head(updateRecommendation.recommendations.filter(rec => rec.id == id));

        if (recomm) {
            recommendationForm.isStopped = recomm == 'stop' ? 1 : 0
        }

        dispatch({
            type: SET_PROPS,
            payload: {
                recommendationForm,
                recommendation_list,
                instruction_list,
                notes_list,
                strength_list
            }
        })
        let brand_name_list = [];
        let brand_names = [];
        dispatch(showNoFormChangesError(false));
        let all_recommendations_data = getState().analytics.all_recommendations_data ? getState().analytics.all_recommendations_data : []
        let recommendationsGroupDTO = all_recommendations_data && all_recommendations_data.recommendationsGroupDTO ? all_recommendations_data.recommendationsGroupDTO : []
        let recommendationSigsDTO = all_recommendations_data && all_recommendations_data.recommendationSigsDTO ? all_recommendations_data.recommendationSigsDTO : []        
        let brand_list = getState().analytics.brand_list ? getState().analytics.brand_list : []
        let recommendation_list = getState().analytics.recommendation_list ? getState().analytics.recommendation_list : []
        let strength_list = getState().analytics.strength_list ? getState().analytics.strength_list : []
        let instruction_list = getState().analytics.instruction_list ? getState().analytics.instruction_list : []
        let notes_list = getState().analytics.notes_list ? getState().analytics.notes_list : []
        let recommedationDTO;
        let group = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.group ? getState().analytics.recommendationForm.group : 'General Instructions';
        let brand = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.brand ? getState().analytics.recommendationForm.brand : '';
        let body = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.body ? getState().analytics.recommendationForm.body : "";
        let strength = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.strength ? getState().analytics.recommendationForm.strength : "";
        let recommendationStrengthType = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.recommendationStrengthType ? getState().analytics.recommendationForm.recommendationStrengthType : "";
        let instructions = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.instructions ? getState().analytics.recommendationForm.instructions : "";
        let notes = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.notes ? getState().analytics.recommendationForm.notes : "";
        let startedAt = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.startedAt ? getState().analytics.recommendationForm.startedAt : "";
        let endedAt = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.endedAt ? getState().analytics.recommendationForm.endedAt : "";
        let recommendation_list_option;
        let recommendationStrength;
        recommendation_list = setRecommendationList(getState().analytics, group)

        if (group) {
            if (brand) {
                recommendation_list = recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(group))
                    .filter((recommendation) => recommendation.brandNames.includes(brand))
                    .map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom } })
            }
            else {
                recommendation_list = recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(group)).map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom } })
            }
        }

        if (recommendation_list) {
            recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(group)).map((rec) => brand_names.push(rec.brandNames))
            brand_name_list = [...new Set(brand_names.flat())]
            if (brand_name_list)
                brand_list = brand_name_list.map((brand) => { return { value: brand } })
            else {
                brand_list = []
            }
        }
        else {
            brand_list = []
        }

        if (group && body && recommendation_list) {
            recommendation_list_option = recommendation_list.find((r) => r.value == body)
            if (recommendation_list_option && recommendation_list_option.id) {
                recommedationDTO = recommendationsGroupDTO.find((rec) => rec.id == recommendation_list_option.id)
            }
            else
                strength_list = []

            if (recommedationDTO && recommedationDTO.recommendationStrengths) {
                strength_list = recommedationDTO.recommendationStrengths.map((rec) => { return { id: rec.strengthId, value: rec.strength } })
                recommendationStrength = recommedationDTO.recommendationStrengths.find((rec) => rec.recommendationId == recommendation_list_option.id && getState().analytics.recommendationForm.strength == rec.strength)
            }
            if (recommedationDTO){
                recommendationForm = { ...recommendationForm, productType: recommedationDTO.productType}
            }

            if (recommedationDTO && recommendationForm.productType && recommendationSigsDTO.find((sigs) => sigs.recommendationForm == recommendationForm.productType)){
                let sigs = [];
                sigs = recommendationSigsDTO.filter((sig) => sig.recommendationForm == recommendationForm.productType)
                instruction_list = sigs.map((sig) => sig.recommendationSigs.map((s)=> { return { value: s } })).flat()
            }
            else if(recommedationDTO && !recommendationForm.productType && recommendationSigsDTO.find((sigs) => sigs.recommendationForm == "")){
                let sigs = [];
                sigs = recommendationSigsDTO.filter((sig) => sig.recommendationForm == "")
                instruction_list = sigs.map((sig) => sig.recommendationSigs.map((s)=> { return { value: s } })).flat()
            }

            else {
                strength_list = []
                instruction_list = []
                notes_list = []
            }

            if (recommendationStrength && recommendationStrength.recommendationInstructions)
                Array.prototype.push.apply(instruction_list, recommendationStrength.recommendationInstructions.map((rec) => { return { id: rec.id, value: rec.instruction } }))    
            if (recommendationStrength && recommendationStrength.recommendationNotes)
                notes_list = recommendationStrength.recommendationNotes.map((rec) => { return { id: rec.id, value: rec.note } })
            else
                notes_list = []
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                brand_list,
                recommendation_list,
                strength_list,
                instruction_list,
                notes_list,
                recommendationForm

            }
        })
        if (updateRecommendation && updateRecommendation.recommendationCurrentRow) {
            group = updateRecommendation.recommendationCurrentRow.group ? updateRecommendation.recommendationCurrentRow.group : 'General Instructions';
            brand = updateRecommendation.recommendationCurrentRow.brand ? updateRecommendation.recommendationCurrentRow.brand : "";
            body = updateRecommendation.recommendationCurrentRow.body ? updateRecommendation.recommendationCurrentRow.body : "";
            instructions = updateRecommendation.recommendationCurrentRow.instructions ? updateRecommendation.recommendationCurrentRow.instructions : "";
            notes = updateRecommendation.recommendationCurrentRow.notes ? updateRecommendation.recommendationCurrentRow.notes : "";
            strength = updateRecommendation.recommendationCurrentRow.strength ? updateRecommendation.recommendationCurrentRow.strength : "";
            recommendationStrengthType = updateRecommendation.recommendationCurrentRow.recommendationStrengthType ? updateRecommendation.recommendationCurrentRow.recommendationStrengthType : "";
            startedAt = updateRecommendation.recommendationCurrentRow.startedAt ? updateRecommendation.recommendationCurrentRow.startedAt : "";
            endedAt = updateRecommendation.recommendationCurrentRow.endedAt ? updateRecommendation.recommendationCurrentRow.endedAt : "";
            recommendationForm = { ...recommendationForm, brand: brand, group: group, body: body, strength: strength, recommendationStrengthType: recommendationStrengthType, instructions: instructions, notes: notes, startedAt: startedAt, endedAt: endedAt };
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendationForm
                }
            })
        }

        dispatch(setInitialRecommendationForm(group, brand, body, strength, recommendationStrengthType, instructions, notes, startedAt, endedAt, recommendationForm.productType));
        if (recomm) {
            dispatch(saveRecommendation())
        }
    }
}

export function updateMetaRecommendations(index) {
    return (dispatch, getState) => {
        let metaRecommendationForm = getState().analytics && getState().analytics.metaRecommendations && getState().analytics.metaRecommendations[index];
        let recommedationDTO
        let strength_list
        let instruction_list
        let notes_list
        let recommendationsGroupDTO = getState().analytics.all_recommendations_data && getState().analytics.all_recommendations_data.recommendationsGroupDTO ? getState().analytics.all_recommendations_data.recommendationsGroupDTO : []
        let group = metaRecommendationForm && metaRecommendationForm.group ? metaRecommendationForm.group : 'General Instructions';
        let recommendation_list;
        let recommendationStrength;
        let recommendation_list_option;
        let body = metaRecommendationForm && metaRecommendationForm.body ? metaRecommendationForm.body : "";
        if (group)
            recommendation_list = recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(group)).map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom} })
        if (group && body && recommendation_list) {
            recommendation_list_option = recommendation_list.find((r) => r.value == body)
            if (recommendation_list_option && recommendation_list_option.id) {
                recommedationDTO = recommendationsGroupDTO.find((rec) => rec.id == recommendation_list_option.id)
            }
            else
                strength_list = []

            if (recommedationDTO && recommedationDTO.recommendationStrengths) {
                strength_list = recommedationDTO.recommendationStrengths.map((rec) => { return { id: rec.strengthId, value: rec.strength } })
                recommendationStrength = recommedationDTO.recommendationStrengths.find((rec) => rec.recommendationId == recommendation_list_option.id)
            }
            else {
                strength_list = []
                instruction_list = []
                notes_list = []
            }

            if (recommendationStrength && recommendationStrength.recommendationInstructions)
                instruction_list = recommendationStrength.recommendationInstructions.map((rec) => { return { id: rec.id, value: rec.instruction } })
            else
                instruction_list = []
            if (recommendationStrength && recommendationStrength.recommendationNotes)
                notes_list = recommendationStrength.recommendationNotes.map((rec) => { return { id: rec.id, value: rec.note } })
            else
                notes_list = []
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                metaRecommendationForm,
                recommendation_list,
                strength_list,
                instruction_list,
                notes_list
            }
        })
    }
}

export function newRecommendation() {
    return (dispatch, getState) => {
        let groupName = getState().analytics.sectionValues && getState().analytics.sectionValues.groupName ?
            getState().analytics.sectionValues.groupName
            : 'General Instructions'
        dispatch({
            type: SET_PROPS,
            payload: {
                recommendationForm: { group: groupName },
                initialRecommendationForm: { group: groupName },
                recommendation_list: setRecommendationList(getState().analytics, groupName),
                brand_list: setBrandList(getState().analytics, groupName),
                strength_list: [],
                instruction_list: [],
                notes_list: []

            }
        })
        dispatch(showNoFormChangesError(false));
    }
}
export function deleteRecommendations(id, code) {
    return (dispatch, getState) => {
        let groupName = getState().analytics.sectionValues && getState().analytics.sectionValues.groupName ?
            getState().analytics.sectionValues.groupName
            : 'General Instructions'
        dispatch({
            type: SET_PROPS,
            payload: {
                isRecommendationsLoading: true,
                recommendationForm: { group: groupName, notes: null, body: null },
                groupName: groupName,
                recommendation_list: setRecommendationList(getState().analytics, groupName),
                brand_list: setBrandList(getState().analytics, groupName)
            }
        })
        let isDemo = getState().analytics.isDemo
        if (!isDemo) {
            let finalDatumResult = getState().analytics.datumValues ? getState().analytics.datumValues.code : ''
            let resCode = code ? code : getState().analytics.ages;
            let datumDrawer = code ? code : finalDatumResult;
            if (resCode && getState().analytics.drawerType == 'biomarker') {
                axios.delete(`${API_URL.RECOMMENDATION_URL}/${id}?code=${resCode}`).then(response => {
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            isRecommendationsLoading: false,
                        }
                    })
                    dispatch(getRecommendations());
                })
            }
            else {
                axios.delete(`${API_URL.RECOMMENDATION_URL}/${id}?code=${datumDrawer}`).then(response => {
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            isRecommendationsLoading: false,
                        }
                    })
                    dispatch(getRecommendations());
                })
            }
        } else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    isRecommendationsLoading: false,
                    recommendationForm: { group: groupName },
                    currentRecommendation: null,
                    recommendation_list: setRecommendationList(getState().analytics, groupName),
                    brand_list: setBrandList(getState().analytics, groupName),
                    recommendations: getState().analytics.recommendations.filter(obj => {
                        if (!code) {
                            if ((obj.id && obj.id != id) || (!obj.id && obj.id != id)) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                        else if (obj.indicators.length && _.head(obj.indicators).toLowerCase() == code.toLowerCase()) {
                            if ((obj.id && obj.id != id) || (!obj.id && obj.id != id)) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                        else {
                            return true;
                        }

                    })
                    // id === undefined ? getState().analytics.recommendations.filter(s => s.id !== undefined && 
                    //     (_.head(s.indicators).toLowerCase() != code.toLowerCase())) : getState().analytics.recommendations.filter(s => s.id !== id)
                }
            })
        }
    }
}

export function saveRecommendation(history, isPlanModal, metaRec) {
    return (dispatch, getState) => {
        let recommendations = getState().analytics;
        let recommendForm = recommendations.recommendationForm
        let metaFlag = false;
        if (getState().analytics.isMetaRecommendation) {
            recommendForm = getState().analytics && getState().analytics.metaRecommendationForm;
            metaFlag = true;
            dispatch(toggleMetaRecommendations(false));
        }
        if (metaRec) {
            recommendForm = metaRec;
        }

        let groupName = getState().analytics.sectionValues && getState().analytics.sectionValues.groupName ?
            getState().analytics.sectionValues.groupName
            : 'General Instructions'
        let demoPath = window.location.pathname.indexOf('demo_1') == 1 ? 'demo_1' : 'demo_2';
        dispatch({
            type: SET_PROPS,
            payload: {
                isRecommendationsLoading: true,
                recommendationForm: ''
            }
        })
        var currentRecommendation = null;
        if (getState().analytics.drawerType == "biomarker") {
            currentRecommendation = {
                providerId: recommendations.presentPatient.providerId,
                visitid: recommendations.presentPatient.latestVisitId,
                ...recommendForm,
                indicators: {
                    recommendationId: recommendations && recommendForm ? recommendForm.id : 0,
                    code: isPlanModal ? '' : (recommendations ? recommendations.ages : '')
                }
            }
        }
        else if (getState().analytics.drawerType == "category") {
            currentRecommendation = {
                providerId: recommendations.presentPatient.providerId,
                visitid: recommendations.presentPatient.latestVisitId,
                ...recommendForm,
                indicators: {
                    recommendationId: recommendations && recommendForm ? recommendForm.id : 0,
                    code: isPlanModal ? '' : (recommendations ? recommendations.currentCategory.key : '')
                }
            }
        }
        else if (getState().analytics.drawerType == "section") {
            currentRecommendation = {
                providerId: recommendations.presentPatient.providerId,
                visitid: recommendations.presentPatient.latestVisitId,
                ...recommendForm,
                indicators: {
                    recommendationId: recommendations && recommendForm ? recommendForm.id : 0,
                    code: isPlanModal ? '' : (recommendations ? recommendations.currentSection.key : '')
                }
            }
        }
        else {
            currentRecommendation = {
                providerId: recommendations.presentPatient.providerId,
                visitid: recommendations.presentPatient.latestVisitId,
                ...recommendForm,
                indicators: {
                    recommendationId: recommendations && recommendForm ? recommendForm.id : 0,
                    code: isPlanModal ? '' : ((recommendations && recommendations.datumValues) ? recommendations.datumValues.code : '')
                }
            }
        }
        currentRecommendation['recommendationId'] = recommendations && recommendForm ? recommendForm.id : 0
        currentRecommendation['isStopped'] = recommendForm.isStopped ? recommendForm.isStopped : 0
        currentRecommendation['priorVisitId'] = recommendations.presentPatient.priorVisitId
        currentRecommendation['patientId'] = recommendations.presentPatient.id
        currentRecommendation['visitId'] = recommendations.presentPatient.latestVisitId
        currentRecommendation['endedAt'] = currentRecommendation.endedAt ? moment(currentRecommendation.endedAt).format('YYYY-MM-DD HH:mm:ss') : null
        currentRecommendation['startedAt'] = currentRecommendation.startedAt ? moment(currentRecommendation.startedAt).format('YYYY-MM-DD HH:mm:ss') : null
        currentRecommendation['isCustomRecommendation'] = getState().analytics.recommendation_list && getState().analytics.recommendation_list.find((r) => r.value == recommendForm.body) || (recommendForm.body == "" || recommendForm.body == null) ?  'false' : 'true' 
        currentRecommendation['IsCustomSig'] = getState().analytics.instruction_list && getState().analytics.instruction_list.find((r) => r.value == recommendForm.instructions) || (recommendForm.instructions == "" || recommendForm.instructions == null)  ?  'false' : 'true' 
        currentRecommendation['siteId'] = recommendations.presentPatient.siteId

        let req = requiredFields.filter(rf => (!currentRecommendation[rf] || parseInt(currentRecommendation['group']) == 0))
        if (req && req.length) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendationErrors: req.map(r => `${requiredFieldText[r]} ${labels.required_label}`),
                    isRecommendationsLoading: false,
                    recommendationForm: { group: currentRecommendation.group, body: currentRecommendation.body, strength: currentRecommendation.strength, recommendationStrengthType: currentRecommendation.recommendationStrengthType, instructions: currentRecommendation.instructions, notes: currentRecommendation.notes, startedAt: currentRecommendation.startedAt, endedAt: currentRecommendation.endedAt },
                    metaRecommendationForm: { group: currentRecommendation.group, body: currentRecommendation.body, recommendationStrengthType: currentRecommendation.recommendationStrengthType }
                }
            })
        }
        else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendationErrors: [],
                    groupName: groupName
                }
            })
            let isDemo = getState().analytics.isDemo
            if (isPlanModal)
                dispatch(togglePlanModal(false))
            if (!isDemo) {
                axios[currentRecommendation.id ? 'put' : 'post'](API_URL.RECOMMENDATION_URL, { ...currentRecommendation }).then((response) => {
                    dispatch(getRecommendations())
                    if (isPlanModal)
                        dispatch(togglePlanModal(false))
                    if (metaRec)
                        dispatch(updateMetaRecommendationCodes(metaRec));
                    if (metaFlag) {
                        dispatch(updateMetaRecommendationCodes(getState().analytics && getState().analytics.metaRecord ? getState().analytics.metaRecord : []));
                        metaFlag = false;
                    }
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            initialRecommendationForm: { group: groupName },
                            resumeRecommendation: false,
                            recommendationCurrentRow: null,
                            recommendation_list: setRecommendationList(getState().analytics, groupName),
                            brand_list: setBrandList(getState().analytics, groupName)
                        }
                    })
                    dispatch(showNoFormChangesError(false));
                }).catch(errors => {
                    let recError = errors.response && errors.response.data ? errors.response.data : ''
                    if (errors.response && errors.response.status == constants.status_codes.unauthorized)
                        dispatch(handleErrors(errors))
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            recommendationErrors: recError ? typeof (recError) == 'string' ? [recError] : recError.value ? [recError.value] : null : null,
                            isRecommendationsLoading: false,
                            recommendationForm: { group: groupName, body: null },
                            initialRecommendationForm: { group: groupName },
                            resumeRecommendation: false,
                            recommendationCurrentRow: null
                        }
                    })
                    dispatch(showNoFormChangesError(false));
                    if (metaRec) {
                        dispatch({
                            type: SET_PROPS,
                            payload: {
                                recommendationErrors: []
                            }
                        })
                    }
                    if (isPlanModal)
                        dispatch(togglePlanModal(true))
                })
            } else {
                let allRecommendations = getState().analytics.recommendations
                    ? getState().analytics.recommendations : demoData.recommendationsInfo[demoPath]

                currentRecommendation = {
                    ...currentRecommendation,
                    indicators: getState().analytics.drawerType == "biomarker" ? [recommendations.ages]
                        : (getState().analytics.drawerType == "category" ? [recommendations ? recommendations.currentCategory.key : ''] : (getState().analytics.drawerType == "section" ? [recommendations ? recommendations.currentSection.key : ''] : [recommendations.datumValues ? recommendations.datumValues.code : '']))
                }
                let finalRec = []
                allRecommendations.map(rec => {
                    if (rec.id && rec.id == currentRecommendation.id)
                        finalRec.push(currentRecommendation)
                    else finalRec.push(rec)
                })
                if (!currentRecommendation.id && finalRec.filter(s => s.id === undefined && (_.head(s.indicators) == _.head(currentRecommendation.indicators))).length < 200) {
                    currentRecommendation = {
                        ...currentRecommendation,
                        id: Math.floor((Math.random() * 1000000) + 1)
                    }
                    finalRec.push(currentRecommendation)
                }

                if (isPlanModal)
                    dispatch(togglePlanModal(false))
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        isRecommendationsLoading: false,
                        recommendationForm: { group: groupName, body: null },
                        metaRecommendationForm: { group: groupName, body: null },
                        currentRecommendation: null,
                        // recommendations: finalRec,
                        recommendations: finalRec && finalRec.length ? finalRec.filter(s => s.isStopped != 1) : [],
                        medications: finalRec && finalRec.length ? finalRec.filter((rec) => constants.medication_groups.includes(rec.group)) : [],
                        initialRecommendationForm: { group: groupName },
                        recommendationCurrentRow: null,
                        recommendation_list: setRecommendationList(getState().analytics, groupName),
                        brand_list: setBrandList(getState().analytics, groupName)
                    }
                })
            }
        }
    }
}


export function getRecommendations(visitId, locale, patientId) {
    return (dispatch, getState) => {
        let id = visitId ? visitId : getState().analytics.presentPatient.latestVisitId;
        locale = locale ? locale : "en"
        patientId = patientId ? patientId : getState().analytics.presentPatient.id
        let groupName = getState().analytics.groupName ? getState().analytics.groupName : 'General Instructions'
        dispatch({
            type: SET_PROPS,
            payload: {
                recommendations: [],
                isRecommendationsLoading: true
            }
        })
        axios.get(`${API_URL.RECOMMENDATION_URL}/${id}?locale=${locale}&patientId=${patientId}&timestamp=${new Date().getTime()}`).then((response) => {
            let recommendations = response.data;
            let sortableKey = constants.recommendation_groups;
            recommendations = recommendations.sort((a, b) => {
                return sortableKey.indexOf(a.group) - sortableKey.indexOf(b.group);
            });
            let initialPriorities = recommendations.map(u => {
                return {
                    priority: u.priority,
                    recommendationId: u.id
                }
            })
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendations: recommendations && recommendations.length ? recommendations.filter(s => s.isStopped != 1) : [],
                    currentVisitRecommendations: recommendations && recommendations.length ? recommendations.filter(s => s.visitId == id) : '',
                    stoppedRecommendations: recommendations && recommendations.length ? recommendations.filter(s => s.isStopped == 1) : [],
                    isReorder: false,
                    initialPriorities,
                    isRecommendationsLoading: false,
                    loadingRecommendations: "finished",
                    recommendationForm: { group: groupName },
                    metaRecommendationForm: { group: groupName }
                }
            })
        }).catch((error) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    isRecommendationsLoading: false,
                    loadingRecommendations: constants.notification_type.error
                }
            })
            dispatch(handleErrors(error))
        })
    }
}
export function toggleMenu(status) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isMenuActive: (status && typeof (status) == 'boolean') ? status : !getState().analytics.isMenuActive,
                // isSideBarActive: status == 'close' ? false : getState().analytics ? !getState().analytics.isSideBarActive : false,
            }
        })
    }
}
export function toggleSideBar(ages, units, dateOfVisit, drawerType, cultureLabel, history, agename) {
    return (dispatch, getState) => {
        dispatch(clearParamsOfRecommendations())
        // if ((window.location && window.location.hash && uniformString(ages) != window.location.hash) && getState().analytics.isSideBarActive && history) {
        //     // history.goBack()
        //     window.history.back()

        //     setTimeout(() => {
        //         if (window.location && window.location.hash && window.history.length <= 3) {
        //             // window.history.back()
        //             history.location = { ...window.location, hash: '' }
        //             history.replace(history.location)
        //         }
        //         else if (window.location && window.location.hash) {
        //             window.history.back()
        //         }
        //     });
        //     document.body.style.overflow = 'auto';
        //     dispatch({
        //         type: SET_PROPS,
        //         payload: {
        //             isSideBarActive: false,
        //         }
        //     })
        // }

        // else 
        if (window.location && !getState().analytics.isSideBarActive && history) {
            // history.location = { ...history.location, hash: `#${uniformString(ages)}` }
            // history.replace(history.location)
            history.push(`#${uniformString(ages)}`)

            document.body.style.overflow = 'hidden';
            // dispatch({
            //     type: SET_PROPS,
            //     payload: {
            //         isSideBarActive: true,
            //     }
            // })
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                isSideBarActive: true,
                ages: ages,
                units: units,
                drawerType,
                historic_data_chart: getState().analytics.historic_data_chart,
                dateOfVisit: dateOfVisit,
                cultureLabel: cultureLabel,
                recommendationErrors: [],
                ageName: agename,
                hashBiomarkerDrawer: null,
                hashDatumDrawer: null,
                hashReportCardDrawer: null,
                hashCategoryDrawer: null,
                hashSectionDrawer: null,
                recommendation_list: setRecommendationList(getState().analytics, 'General Instructions'),
                brand_list: setBrandList(getState().analytics, 'General Instructions')
            }
        })

    }
}
export function toggleDatumSideBar(datumValues, sectionValues, drawerType, history) {
    return (dispatch, getState) => {
        dispatch(clearParamsOfRecommendations())
        // if ((window.location && window.location.hash && uniformString(datumValues.code) != window.location.hash) && history && getState().analytics.isSideBarActive) {
        //     // history.goBack()
        //     window.history.back()
        //     setTimeout(() => {
        //         if (window.location && window.location.hash && window.history.length <= 3) {
        //             // window.history.back()
        //             history.location = { ...window.location, hash: '' }
        //             history.replace(history.location)
        //         }
        //         else if (window.location && window.location.hash) {
        //             window.history.back()
        //         }
        //     });
        //     document.body.style.overflow = 'auto';
        //     dispatch({
        //         type: SET_PROPS,
        //         payload: {
        //             isSideBarActive: false,
        //         }
        //     })
        // }
        // else
        if (window.location && history && !getState().analytics.isSideBarActive) {
            // history.location = { ...history.location, hash: `#${uniformString(datumValues.code)}` }
            // history.replace(history.location)
            if (history.location && history.location.hash != `#${uniformString(datumValues.code)}`)
                history.push(`#${uniformString(datumValues.code)}`)

            document.body.style.overflow = 'hidden';
            // dispatch({
            //     type: SET_PROPS,
            //     payload: {
            //         isSideBarActive: true,
            //     }
            // })
        }
        // if (getState().analytics.isSideBarActive)
        //     setTimeout(() => dispatch(hideSpectrum(false)), 2000)

        let groupName = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.group ? getState().analytics.recommendationForm.group : setGroupName(sectionValues)
        let recommendationForm = getState().analytics && getState().analytics.recommendationForm ? getState().analytics.recommendationForm : { group: groupName }
        let brand = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.brand ? getState().analytics.recommendationForm.brand : ""
        let recommendation_list = setRecommendationList(getState().analytics, groupName, brand)
        let recommendation = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.body ? getState().analytics.recommendationForm.body : ""
        let strength = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.strength ? getState().analytics.recommendationForm.strength : ""
        let instructionAndNotes = setInstructionAndNotes(getState().analytics, recommendation, recommendation_list, strength)
        let instruction_list = instructionAndNotes[0]
        let notes_list = instructionAndNotes[1]

        dispatch({
            type: SET_PROPS,
            payload: {
                isSideBarActive: true,
                datumValues,
                sectionValues,
                drawerType,
                recommendationErrors: [],
                hashBiomarkerDrawer: null,
                hashDatumDrawer: null,
                isShowSpectrum: true,
                hashReportCardDrawer: null,
                hashCategoryDrawer: null,
                hashSectionDrawer: null,
                recommendationForm: recommendationForm,
                initialRecommendationForm: getState().analytics.initialRecommendationForm ? getState().analytics.initialRecommendationForm : { group: groupName },
                recommendation_list: recommendation_list,
                brand_list: setBrandList(getState().analytics, groupName),
                strength_list: setStrengthList(getState().analytics, recommendation, recommendation_list),
                instruction_list: instruction_list,
                notes_list: notes_list
            }
        })

    }
}
export function toggleGradeSideBar(drawerType, history) {
    return (dispatch, getState) => {
        dispatch(clearParamsOfRecommendations())
        if (window.location && history && !getState().analytics.isSideBarActive) {
            // history.location = { ...history.location, hash: `#${uniformString(datumValues.code)}` }
            // history.replace(history.location)
            // if (history.location && history.location.hash != `#${uniformString("overall-grade-drawer")}`)
            history.push(`#${uniformString("overall-grade-drawer")}`)

            document.body.style.overflow = 'hidden';
            // dispatch({
            //     type: SET_PROPS,
            //     payload: {
            //         isSideBarActive: true,
            //     }
            // })
        }
        // if (getState().analytics.isSideBarActive)
        //     setTimeout(() => dispatch(hideSpectrum(false)), 2000)

        dispatch({
            type: SET_PROPS,
            payload: {
                isSideBarActive: true,
                drawerType,
                hashBiomarkerDrawer: null,
                hashDatumDrawer: null,
                hashReportCardDrawer: null,
                hashCategoryDrawer: null,
                hashSectionDrawer: null
            }
        })

    }
}
export function toggleCategorySideBar(drawerType, history, categoryRow, categoryGradesData) {
    return (dispatch, getState) => {
        dispatch(clearParamsOfRecommendations())
        if (window.location && history && !getState().analytics.isSideBarActive) {
            history.push(`#${uniformString(categoryRow.key + "-drawer")}`)
            document.body.style.overflow = 'hidden';
        }
        var categoryGradesHistoric = categoryGradesData && categoryGradesData.length ?
            {
                value: _.last(categoryGradesData).value,
                points: _.last(categoryGradesData).points,
                visitDate: _.last(categoryGradesData).visitDate,
                visitId: _.last(categoryGradesData).visitId,
                historicData: categoryGradesData
            } : null

        dispatch({
            type: SET_PROPS,
            payload: {
                isSideBarActive: true,
                drawerType,
                hashBiomarkerDrawer: null,
                hashDatumDrawer: null,
                hashReportCardDrawer: null,
                hashCategoryDrawer: null,
                hashSectionDrawer: null,
                categoryGradesData,
                currentCategory: categoryRow,
                categoryGradesHistoric
            }
        })

    }
}

export function toggleSectionSideBar(drawerType, history, sectionRow, sectionGradesData, categoryData) {
    return (dispatch, getState) => {
        dispatch(clearParamsOfRecommendations())
        if (window.location && history && !getState().analytics.isSideBarActive) {
            history.push(`#${uniformString(sectionRow.key + "-drawer")}`)
            document.body.style.overflow = 'hidden';
        }
        var sectionGradesHistoric = sectionGradesData && sectionGradesData.length ?
            {
                value: _.last(sectionGradesData).value,
                points: _.last(sectionGradesData).points,
                visitDate: _.last(sectionGradesData).visitDate,
                visitId: _.last(sectionGradesData).visitId,
                historicData: sectionGradesData
            } : null

        let sectionValues = sectionGradesData && sectionGradesData.length ? _.head(sectionGradesData) : []
        let groupName = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.group ? getState().analytics.recommendationForm.group : setGroupName(sectionValues)
        let recommendationForm = getState().analytics && getState().analytics.recommendationForm ? getState().analytics.recommendationForm : { group: groupName }
        let brand = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.brand ? getState().analytics.recommendationForm.brand : ""
        let recommendation_list = setRecommendationList(getState().analytics, groupName, brand)
        let recommendation = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.body ? getState().analytics.recommendationForm.body : ""
        let strength = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.strength ? getState().analytics.recommendationForm.strength : ""
        let instructionAndNotes = setInstructionAndNotes(getState().analytics, recommendation, recommendation_list, strength)
        let instruction_list = instructionAndNotes[0]
        let notes_list = instructionAndNotes[1]

        dispatch({
            type: SET_PROPS,
            payload: {
                isSideBarActive: true,
                drawerType,
                hashBiomarkerDrawer: null,
                hashDatumDrawer: null,
                hashReportCardDrawer: null,
                hashCategoryDrawer: null,
                hashSectionDrawer: null,
                sectionGradesData,
                currentSection: sectionRow,
                sectionGradesHistoric,
                categorySectionData: categoryData,
                recommendationForm: recommendationForm,
                initialRecommendationForm: getState().analytics.initialRecommendationForm ? getState().analytics.initialRecommendationForm : { group: groupName },
                recommendation_list: recommendation_list,
                brand_list: setBrandList(getState().analytics, groupName),
                strength_list: setStrengthList(getState().analytics, recommendation, recommendation_list),
                instruction_list: instruction_list,
                notes_list: notes_list,
                sectionValues
            }
        })
    }
}

function setGroupName(sectionValues) {
    return sectionValues && sectionValues.groupName ?
        sectionValues.groupName : 'General Instructions'
}

function setRecommendationList(analytics, groupName, brand) {
    let recommendation_list = [];
    if (analytics && analytics.all_recommendations_data) {
        let recommendation = analytics.all_recommendations_data.recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(groupName))
        if (brand) {
            recommendation_list = recommendation.filter((rec) => rec.brandNames.includes(brand))
                .map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom} })
        }
        else {
            recommendation_list = recommendation ? recommendation.map((rec) => { return { id: rec.id, value: rec.bodyWithProduct ? rec.bodyWithProduct : rec.body, isCustom: rec.isCustom } }) : []
        }
    }
    return [...new Map(recommendation_list.map(item => [JSON.stringify(item), item])).values()]
    // .slice(0,20);
}
function setBrandList(analytics, groupName) {
    let brand_list = [];
    let brand_name_list = [];
    let brand_names = [];
    if (analytics && analytics.all_recommendations_data) {
        analytics.all_recommendations_data.recommendationsGroupDTO.filter((recommendation) => recommendation.groupNames.includes(groupName)).map((rec) => brand_names.push(rec.brandNames))
        brand_name_list = [...new Set(brand_names.flat())]
        if (brand_name_list)
            brand_list = brand_name_list.map((brand) => { return { value: brand } })
        else {
            brand_list = []
        }
    }
    return brand_list
}

function setStrengthList(analytics, recommendation, recommendation_list) {
    let recommendationsGroupDTO = analytics.all_recommendations_data && analytics.all_recommendations_data.recommendationsGroupDTO ? analytics.all_recommendations_data.recommendationsGroupDTO : []
    let recommendation_list_option;
    let recommedationDTO;
    let strength_list;
    if (recommendationsGroupDTO && recommendation_list && recommendation) {
        recommendation_list_option = recommendation_list.find((r) => r.value == recommendation)
        if (recommendation_list_option && recommendation_list_option.id) {
            recommedationDTO = recommendationsGroupDTO.find((rec) => rec.id == recommendation_list_option.id)
        }
        else
            strength_list = []
        if (recommedationDTO && recommedationDTO.recommendationStrengths)
            strength_list = recommedationDTO.recommendationStrengths.map((rec) => { return { id: rec.strengthId, value: rec.strength } })
        else
            strength_list = []
    }
    else {
        strength_list = []
    }
    return strength_list
}

function setInstructionAndNotes(analytics, recommendation, recommendation_list, strength) {
    let recommendationsGroupDTO = analytics.all_recommendations_data && analytics.all_recommendations_data.recommendationsGroupDTO ? analytics.all_recommendations_data.recommendationsGroupDTO : []
    let recommendation_list_option;
    let recommedationDTO;
    let instruction_list = [];
    let notes_list = [];
    let recommendationStrength;

    if (recommendationsGroupDTO && strength && recommendation_list) {
        recommendation_list_option = recommendation_list.find((r) => r.value == recommendation)

        if (recommendation_list_option && recommendation_list_option.id) {
            recommedationDTO = recommendationsGroupDTO.find((rec) => rec.id == recommendation_list_option.id)
        }
        else {
            instruction_list = []
            notes_list = []

        }
        if (recommedationDTO && recommedationDTO.recommendationStrengths) {
            recommendationStrength = recommedationDTO.recommendationStrengths.find((rec) => rec.recommendationId == recommendation_list_option.id
                && strength == rec.strength)
        }
        else {
            instruction_list = []
            notes_list = []
        }
        if (recommendationStrength && recommendationStrength.recommendationInstructions)
            instruction_list = recommendationStrength.recommendationInstructions.map((rec) => { return { id: rec.id, value: rec.instruction } })
        else
            instruction_list = []

        if (recommendationStrength && recommendationStrength.recommendationNotes)
            notes_list = recommendationStrength.recommendationNotes.map((rec) => { return { id: rec.id, value: rec.note } })
        else
            notes_list = []
    }
    else {
        instruction_list = []
        notes_list = []
    }
    return [instruction_list, notes_list]
}

export function closeSideBar(history, isCloseDrawer) {
    return (dispatch, getState) => {
        // if (window.location && window.location.hash && history && getRouteStatus()) {
        //     // localStorage.removeItem('canOpen')
        //     history.location = { ...window.location, hash: '' }
        //     history.replace(history)
        // } else if (window.location && window.location.hash && history) {
        // if (window.location && window.location.hash && history && getRouteStatus()) {
        // window.history.back()
        // if (window.location && window.location.hash && history)
        // } else if (window.history.length <= 3) {
        //     history.location = { ...window.location, hash: '' }
        //     history.replace(history.location)
        // } else if(history){
        //     window.history.back()
        // }
        // if (window.location && window.location.hash && history && getRouteStatus()) {
        //     history.location = { ...window.location, hash: '' }
        //     history.replace(history.location)
        // } else {

        let group = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.group ? getState().analytics.recommendationForm.group : 'General Instructions'
        let body = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.body ? getState().analytics.recommendationForm.body : "";
        let brand = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.brand ? getState().analytics.recommendationForm.brand : "";
        let strength = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.strength ? getState().analytics.recommendationForm.strength : "";
        let recommendationStrengthType = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.recommendationStrengthType ? getState().analytics.recommendationForm.recommendationStrengthType : "";
        let instructions = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.instructions ? getState().analytics.recommendationForm.instructions : "";
        let notes = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.notes ? getState().analytics.recommendationForm.notes : "";
        let startedAt = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.startedAt ? getState().analytics.recommendationForm.startedAt : "";
        let endedAt = getState().analytics && getState().analytics.recommendationForm && getState().analytics.recommendationForm.endedAt ? getState().analytics.recommendationForm.endedAt : "";

        let recommendationForm = { group: group, brand: brand, body: body, strength: strength, recommendationStrengthType: recommendationStrengthType, instructions: instructions, notes: notes, startedAt: startedAt, endedAt: endedAt }
        let initialRecommendationForm = getState().analytics && getState().analytics.initialRecommendationForm ? { ...getState().analytics.initialRecommendationForm } : { group: 'General Instructions' };


        Object.keys(recommendationForm).forEach((p) => recommendationForm[p] == null || recommendationForm[p] == undefined || recommendationForm[p] == "" ? delete recommendationForm[p] : '');
        Object.keys(initialRecommendationForm).forEach((p) => initialRecommendationForm[p] == null || initialRecommendationForm[p] == undefined || initialRecommendationForm[p] == "" ? delete initialRecommendationForm[p] : '');
        if (recommendationForm == "" || _.isEqual(recommendationForm, initialRecommendationForm) || isCloseDrawer) {

            document.body.style.overflow = 'auto';
            // if (history && (history.length <= 3 || getRouteStatus())) {
            if (history) {
                history.location = { ...history.location, hash: '' }
                history.push(history.location)
            }

            // } else if(history) {
            //     history.goBack()
            // }
            dispatch(showNoFormChangesError(false));
            dispatch({
                type: SET_PROPS,
                payload: {
                    isSideBarActive: false,
                    recommendationForm: { group: 'General Instructions' },
                    sectionValues: {},
                    recommendationErrors: [],
                    isRecommendationsLoading: false,
                    recommendationForm: { group: 'General Instructions' },
                    hashBiomarkerDrawer: null,
                    hashDatumDrawer: null,
                    isShowSpectrum: false,
                    hashReportCardDrawer: null,
                    hashCategoryDrawer: null,
                    hashSectionDrawer: null,
                    initialRecommendationForm: { group: 'General Instructions' },
                    analyticsUnitPreference: null,
                    recommendation_list: [],
                    instruction_list: [],
                    notes_list: []
                }
            })
            if (isCloseDrawer && getState().analytics && getState().analytics.isDemo) {
                window.history.pushState({}, document.title, window.location.href.split('#')[0]);
            }
            // dispatch(hideSpectrum(true));

            // } else {
            //     window.history.back()
            // }
            // history.goBack()
            // }

            // }
        }
        else {
            dispatch(toggleDrawerWarning(true));
            let drawerType = getState().analytics && getState().analytics.drawerType ? getState().analytics.drawerType : 'datum';
            let code = '';
            switch (drawerType) {
                case 'datum': code = getState().analytics && getState().analytics.datumValues && getState().analytics.datumValues.code ? uniformString(getState().analytics.datumValues.code) : ''; break;
                case 'section': code = getState().analytics && getState().analytics.currentSection && getState().analytics.currentSection.key ? `${uniformString(getState().analytics.currentSection.key)}-drawer` : ''; break;
                case 'category': code = getState().analytics && getState().analytics.currentCategory && getState().analytics.currentCategory.key ? `${uniformString(getState().analytics.currentCategory.key)}-drawer` : ''; break;
                case 'biomarker': code = getState().analytics && getState().analytics.ages ? uniformString(getState().analytics.ages) : ''; break;
                default: code = '';
            }
            if (window.location.hash != `#${code}` && code != '') {
                window.location.hash = `#${code}`
            }
        }

    }
}
export function closeSideBarOnClose() {

    return (dispatch, getState) => {
        document.body.style.overflow = 'auto';
        if (getState().analytics.isSideBarActive) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    isSideBarActive: false,
                    recommendationForm: { group: 'General Instructions' },
                    sectionValues: {},
                    recommendationErrors: [],
                    isRecommendationsLoading: false,
                    recommendationForm: { group: 'General Instructions' },
                    hashBiomarkerDrawer: null,
                    hashDatumDrawer: null,
                    hashReportCardDrawer: null,
                    hashCategoryDrawer: null,
                    hashSectionDrawer: null,
                    recommendation_list: [],
                    instruction_list: [],
                    notes_list: []
                }
            })
        }
        // dispatch(hideSpectrum(true));
    }

}
export function setInitialHash(hash) {
    return (dispatch, getState) => {
        let { hashBiomarkerDrawer, hashDatumDrawer, hashReportCardDrawer, hashCategoryDrawer, hashSectionDrawer } = { ...getState().analytics }
        // dispatch({
        //     type: SET_PROPS,
        //     payload: {
        //         hashBiomarkerDrawer: null,
        //         hashDatumDrawer: null
        //     }
        // })
        // if (!hashBiomarkerDrawer && !hashDatumDrawer)
        if (Object.values(constants.biographical_labels.agesForDrawer).indexOf(hash) != -1) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    hashBiomarkerDrawer: hash,
                    hashDatumDrawer: null,
                    hashReportCardDrawer: null,
                    hashCategoryDrawer: null,
                    hashSectionDrawer: null
                }
            })
        }
        else if (hash == 'overall-grade-drawer') {
            dispatch({
                type: SET_PROPS,
                payload: {
                    hashReportCardDrawer: hash ? hash : null,
                    hashBiomarkerDrawer: null,
                    hashDatumDrawer: null,
                    hashCategoryDrawer: null,
                    hashSectionDrawer: null
                }
            })
        }
        else if (hash && hash.indexOf('category-drawer') != -1) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    hashCategoryDrawer: hash,
                    hashReportCardDrawer: null,
                    hashBiomarkerDrawer: null,
                    hashDatumDrawer: null,
                    hashSectionDrawer: null
                }
            })
        }
        else if (hash && hash.indexOf('section-drawer') != -1) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    hashSectionDrawer: hash,
                    hashReportCardDrawer: null,
                    hashBiomarkerDrawer: null,
                    hashDatumDrawer: null,
                    hashCategoryDrawer: null
                }
            })
        }
        else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    hashDatumDrawer: hash ? hash : null,
                    hashBiomarkerDrawer: null,
                    hashReportCardDrawer: null,
                    hashCategoryDrawer: null,
                    hashSectionDrawer: null
                }
            })
        }
    }
}
export function setBiomarkersDemoData(historicObject) {
    return (dispatch, getState) => {
        var result = {}
        var biographical_labels = constants.biographical_labels.historic_data_labels;
        Object.keys(historicObject).map(s => {
            if (Object.keys(biographical_labels).includes(historicObject[s].code)) {
                result[biographical_labels[historicObject[s].code]] = []

                if (historicObject[s].historicData != null && historicObject[s].historicData.length) {
                    for (var i = 0; i < historicObject[s].historicData.length; i++) {
                        result[biographical_labels[historicObject[s].code]].push(historicObject[s].historicData[i])
                    }
                }
                else {
                    result[biographical_labels[historicObject[s].code]].push(historicObject[s])
                }
                if (historicObject[s].noumenonExplanation)
                    _.head(result[biographical_labels[historicObject[s].code]]).noumenonExplanation = historicObject[s].noumenonExplanation
            }
        });
        var biomarkerCount = 0;
        var sortedResult = {};
        if (result && Object.keys(result).length) {
            Object.keys(result).map(s => result[s].length).map(s => biomarkerCount = biomarkerCount + s);
            Object.keys(biographical_labels).forEach((label) => {

                sortedResult[biographical_labels[label]] = result[biographical_labels[label]]

            });
            sortedResult = _.pickBy(sortedResult);
        }
        sortedResult.biomarkerCount = biomarkerCount;
        dispatch(setComparingChartsData(sortedResult))
    }
}
export function getAllCategories(visitId, locale, isSummary, history, isMobile) {
    return (dispatch, getState) => {
        let role = getState().global && getState().global.loggedInUser
            && getState().global.loggedInUser.role ? getState().global.loggedInUser.role
            : constants.logged_roles[localStorage.getItem(constants.role)]

        axios.get(`${API_URL.ANALYTICS_BIO_INFO}/?cultureInfo=${locale}`).then((category) => {

            dispatch({
                type: SET_PROPS,
                payload: {
                    category: category.data,
                    loadingCategories: "finished",
                    loadingSections: "finished"
                }
            })
            if (isSummary) {
                dispatch(handleLoading())
            }
        }).catch(errors => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    loadingCategories: constants.notification_type.error,
                    loadingSections: constants.notification_type.error
                }
            })
            dispatch(handleErrors(errors));
            dispatch(handleSummaryLoading(false))
        })

        let query = `cultureInfo=${locale}&timestamp=${new Date().getTime()}`
        if (isSummary) {
            query += '&loadHistory=false&loadInterpretations=true'
        }
        if (!isSummary) {
            if (role != 'patient') {
                query += '&loadRecommendations=true&loadExplanations=true&loadInterpretations=true'
            }
            else {
                query += '&loadExplanations=true&loadInterpretations=true'
            }
        }
        // if (!isSummary && role != 'patient') {
        //     query += '&loadRecommendations=true'
        // }
        axios.get(`${API_URL.ANALYTICS_HISTORIC_CATEGOREY}/${visitId}?${query}`).then((response) => {



            //-------------------------------biomarkers of aging-----------------------------------
            var result = {}
            var biographical_labels = constants.biographical_labels.historic_data_labels;
            Object.keys(response.data).map(s => {
                if (Object.keys(biographical_labels).includes(response.data[s].code)) {
                    result[biographical_labels[response.data[s].code]] = []

                    if (response.data[s].historicData != null && response.data[s].historicData.length) {
                        for (var i = 0; i < response.data[s].historicData.length; i++) {
                            result[biographical_labels[response.data[s].code]].push(response.data[s].historicData[i])
                        }
                    }
                    else {
                        result[biographical_labels[response.data[s].code]].push(response.data[s])
                    }
                    if (response.data[s].noumenonExplanation)
                        _.head(result[biographical_labels[response.data[s].code]]).noumenonExplanation = response.data[s].noumenonExplanation
                }
            });
            var biomarkerCount = 0;
            var sortedResult = {};
            if (result && Object.keys(result).length) {
                Object.keys(result).map(s => result[s].length).map(s => biomarkerCount = biomarkerCount + s);
                Object.keys(biographical_labels).forEach((label) => {

                    sortedResult[biographical_labels[label]] = result[biographical_labels[label]]

                });
                sortedResult = _.pickBy(sortedResult);
            }
            sortedResult.biomarkerCount = biomarkerCount;
            dispatch(setBiomarkersOfAging(getState().analytics.presentPatient.id,
                locale
                , isSummary ? parseInt(visitId) : null, sortedResult))

            //----------------------------biomarkers ends here------------------------------------

            let labelsForBioInfo = constants.labelsForBioInfo;
            var biographicalInformation = {}, bioInformation = {}
            let metaRecommendations = response.data && response.data.length > 0 ? _.head(response.data).metaRecommendations : [];
            let metaRecommendationCodes = response.data && response.data.length > 0 ? _.head(response.data).metaRecommendationCodes : [];
            let interpretations = response.data && response.data.length > 0 ? _.head(response.data).interpretations : [];

            //preparing for Biographical Information
            //Ex: Smoker? : Current


            var finalVals = response.data ? labelsForBioInfo.map(s => {
                return response.data.find(t => t.code == s)
            }) : ''

            //removing undefined or null values using compact
            if (_.compact(finalVals) && _.compact(finalVals).length) {
                _.compact(finalVals).map(t => { biographicalInformation[t.code] = (t.historicData && t.historicData.length ? _.last(t.historicData).value : t.value) })
            }

            //Preparing the biographical information as key value pairs
            //default will be unknown if the value is not specified
            Object.keys(constants.biographical_labels.history_component).map(s => {
                if (Object.keys(biographicalInformation).length && Object.keys(biographicalInformation).indexOf(s) != -1) {
                    return bioInformation[constants.biographical_labels.history_component[s]] = biographicalInformation[s]
                } else {
                    return bioInformation[constants.biographical_labels.history_component[s]] = constants.unknown
                }
            })

            dispatch({
                type: SET_PROPS,
                payload: {
                    Data: response.data,
                    bioInformation,
                    metaRecommendations,
                    metaRecommendationCodes,
                    interpretations
                }
            })
            dispatch(setDefaultMetaExpansions());
            if (isSummary) {
                setTimeout(() => {
                    dispatch(downloadVisitSummary(locale, visitId, history, isMobile))
                    dispatch(toggleLoading(true))
                }, 1000);
            }
            dispatch(dataSelector(isSummary, visitId))
        }).catch(errors => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    loadingData: constants.notification_type.error
                }
            })
            dispatch(handleStatusCodes(errors));
            dispatch(handleErrors(errors));
            dispatch(handleSummaryLoading(false))
        })

    }
}

export function getCustomRecommendation(siteId){
    return (dispatch, getState) => {
        if (getState().analytics && !getState().analytics.isDemo){
            dispatch(toggleLoading(true))
            let all_recommendations_data = getState().analytics.all_recommendations_data && getState().analytics.all_recommendations_data ? getState().analytics.all_recommendations_data : []
            let recommendationsGroupDTOS = all_recommendations_data && all_recommendations_data.recommendationsGroupDTO ? all_recommendations_data.recommendationsGroupDTO : []
            axios.get(`${API_URL.GET_CUSTOM_RECOMMNEDATION}?siteId=${siteId}`).then((response) => {
                Array.prototype.push.apply(recommendationsGroupDTOS, response.data)
                recommendationsGroupDTOS = [...new Set(recommendationsGroupDTOS)]
                all_recommendations_data.recommendationsGroupDTO = recommendationsGroupDTOS
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        all_recommendations_data: all_recommendations_data
                    }
                })
            dispatch(toggleLoading(false))
            }).catch((error) => {
                dispatch(toggleLoading(false))
                dispatch(handleErrors(error))
            })
        }    
    }
}

export function getCustomSigs(siteId){
    return (dispatch, getState) => {
        if (getState().analytics && !getState().analytics.isDemo){
            dispatch(toggleLoading(true))
            let all_recommendations_data = getState().analytics.all_recommendations_data && getState().analytics.all_recommendations_data ? getState().analytics.all_recommendations_data : []
            let recommendation_SIGs = all_recommendations_data && all_recommendations_data.recommendationSigsDTO ? all_recommendations_data.recommendationSigsDTO : []
            axios.get(`${API_URL.GET_CUSTOM_SIG}?siteId=${siteId}`).then((response) => {
                Array.prototype.push.apply(recommendation_SIGs, response.data)
                recommendation_SIGs = [...new Set(recommendation_SIGs)]
                all_recommendations_data.recommendationSigsDTO = recommendation_SIGs
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        all_recommendations_data: all_recommendations_data
                    }
                })
            dispatch(toggleLoading(false))
            }).catch((error) => {
                dispatch(toggleLoading(false))
                dispatch(handleErrors(error))
            })
        }    
    }
}

function dataSelector(isSummary, visitId) {
    return (dispatch, getState) => {
        let categoryData = getState().analytics.category;
        // let categoriesGradeData = cloneDeep(getState().analytics.category);
        let data = getState().analytics.Data;
        let categories = isSummary ? datasetSelector({ categoryData, data, visitId }) : datasetSelector({ categoryData, data });
        //enable time boundaries when required
        let timeBoundaries = !isSummary ? timeBoundarySelector({ data }) : null
        let gpa = gpaSelector(categories)
        let visitList = getState().analytics.allVisitData
        var grade_historic_data = []
        visitList && visitList.length ?
            visitList.map(s => {
                let visitNumber = s.id,
                    dateOfVisit = s.visitDate,
                    categoriesGradeData = cloneDeep(getState().analytics.category)
                let testCat = gradeSelector({ categoriesGradeData, data, visitNumber, dateOfVisit })
                grade_historic_data.push((testCat))
            })
            : ''
        var grading_data =
            grade_historic_data && grade_historic_data.length && grade_historic_data.map(t => {
                if (t && t.length) {
                    return {
                        visitId: _.head(_.head((_.head(t).sections.map(s => s.data)))).visitId,
                        visitDate: _.head(_.head((_.head(t).sections.map(s => s.data)))).visitDate,
                        points: round(_.mean(t.filter(u => u.points).map(v => v.points)), 2),
                        value: round(_.mean(t.filter(u => u.points).map(v => v.points)), 2),
                        data: t,
                        label: 'report-grades',
                        type: 'points'
                    }
                }
            }).filter(p => p && !isNaN(p.points))
        var grade_historic_data =
            grading_data && grading_data.length ?
                grade_historic_data =
                {
                    value: _.last(grading_data).value,
                    points: _.last(grading_data).points,
                    visitDate: _.last(grading_data).visitDate,
                    visitId: _.last(grading_data).visitId,
                    historicData: grading_data
                } : null
        dispatch({
            type: SET_PROPS,
            payload: {
                categories, gpa,
                timeBoundaries,
                loadingData: "finished",
                grading_data,
                grade_historic_data
            }
        })
    }
}
export function getTerms(locale, isSummary) {
    return (dispatch) => {
        axios.get(`${API_URL.TERM_TRANSLATIONS}?locale=${locale}`).then(response => {
            let terms = {}
            if (isSummary)
                response.data.map(t => { terms[t.key] = t.termTranslationsDTOList.text.replace(/％/g, '').replace(/%/g, '') })
            else
                response.data.map(t => { terms[t.key] = t.termTranslationsDTOList.text })
            dispatch({
                type: SET_PROPS,
                payload: {
                    terms
                }
            })
        }).catch(error => dispatch(handleErrors(error)))
    }
}
function exitAnalytics(patientId, history) {
    return (dispatch, getState) => {
        let role = getState().global && getState().global.loggedInUser
            && getState().global.loggedInUser.role ? getState().global.loggedInUser.role
            : constants.logged_roles[localStorage.getItem(constants.role)]
        if (role == 'patient') {
            dispatch(logOut(history))
        } else {
            history.push(`/patients/${patientId}`)
        }

    }
}
function resetLoader(patientId) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                loadingData: "pending",
                loadingCategories: "pending",
                loadingSections: "pending",
                loadingPatient: "pending",
                loadingRecommendations: "pending",
                loadingMedications: "pending",
                presentPatient: {},
                siteData: null,
                historic_data_chart: null,
                categories: null
            }
        })
        dispatch(setCurrentHealthAnalytics(patientId))

    }
}
function downloadPatientReport(visitId, history, pastData) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch(setPatientReportStatus(true))
        dispatch(setHeaderType(null, false))
        dispatch(setCurrentVisit(visitId, history, true, undefined, undefined, pastData ? true : false, pastData ? "patientReportDownloadTemplatePast" : "patientReportDownloadTemplate", true))
    }
}
function updateResolution() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                resolution: window.innerWidth
            }
        })
    }
}
function setDemoStatus(status) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isDemo: status
            }
        })
    }
}
function setVisitSummary(visitId, locale, history, isMobile) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch(toggleReportLoading(true))
        let isDemo = getState().analytics.isDemo
        let isDemo1 = getState().analytics.isDemo1
        dispatch({
            type: SET_PROPS,
            payload: {
                downloadableFile: null,
                isSummaryLoading: true
            }
        })
        if (!isDemo && !isNaN(parseInt(visitId))) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentSummaryVisitId: visitId,
                    isVisitSummary: true
                }
            })
            axios.get(`${API_URL.VISIT_URL}/${visitId}`).then((response) => {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        currentTimepoint: parseInt(response.data.timePoint.replace(/^\D+/g, ''))
                    }
                })
                dispatch(setAnalytics(response.data.patientId, true, visitId, locale, history, isMobile))
            }).catch((error) => {
                dispatch(handleStatusCodes(error))
                dispatch(handleErrors(error))
            })
        } else if (isDemo) {
            isDemo1 ? dispatch(toDataURL(demoSummary, 'downloadableFile'))
                : dispatch(toDataURL(demo2Summary, 'downloadableFile'))

            dispatch(handleSummaryLoading(false))
            dispatch(toggleLoading(false))
        }
    }
}
function handleDownloadVisitSummary(history) {
    return (dispatch, getState) => {
        let presentPatient = getState().analytics.presentPatient
        let lastestVisitDataentry = getState().analytics.lastestVisitDataentry
        let isDemo = getState().analytics.isDemo
        let isDemo1 = getState().analytics.isDemo1;
        if (!isDemo) {
            if (lastestVisitDataentry && lastestVisitDataentry.indexOf(true) == -1) {
                if (getState().analytics.role == constants.logged_roles.PT) {
                    history.push(`/analytics`)
                }
                else {
                    history.push(`/visits/${presentPatient.latestVisitId}`)
                }
                setTimeout(() => {
                    dispatch(toggleNotification(true, 'error', '', [labels.visit_page_labels.no_recommandations_caution], true))
                }, 2000);
            } else {
                history.push(`/visits/${presentPatient.latestVisitId}/summary.pdf?locale=${presentPatient.patientLanguagePref ? presentPatient.patientLanguagePref : 'en'}`)
            }
        } else {
            isDemo1 ? history.push('/sample_data/demo_1_visit_summary.pdf')
                : history.push('/sample_data/demo_2_visit_summary.pdf')
        }
    }
}
function getFullName(detail) {
    return detail ? `${detail.honorificPrefix ? `${detail.honorificPrefix} ` : ''}${detail.firstname} ${detail.lastname}${detail.honorificSuffix ? `, ${detail.honorificSuffix}` : ''}` : null
}
function getFormat(locale) {
    if (locale == 'en' || locale == 'ro' || locale == "pl")
        return 'MMMM, YYYY'
    else if (locale == 'es')
        return 'MMMM YYYY'
    else if (locale == 'ja')
        return 'YYYY年MMMM'
    else
        return 'MMMM, YYYY'
}
function hideSpectrum(status) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isShowSpectrum: !status
            }
        })
    }
}
function handleSummaryLoading(status) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isSummaryLoading: status
            }
        })
    }
}
function downloadVisitSummary(locale, visitId, history, isMobile) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isVisitSummary: true
            }
        })
        dispatch(toggleLoading(true))
        setTimeout(() => {
            let analytics = getState().analytics
            let isDemo = analytics.isDemo
            let visitSubtitle = analytics.terms['visit_summary_subtitle'].replace(/％/g, '').replace(/%/g, '')
            let pagetitleSuffix = visitSubtitle.replace('{patient_name}', getFullName(analytics.presentPatient)).replace('{month_and_year}'
                , analytics.currentPatientAging
                    ? moment(new Date(analytics.currentPatientAging.visitDate))
                        .locale(locale).format(getFormat(locale)) : '')
            let header = `<div class=\"page-header\" style=\"display: flex; width: 100%;margin-bottom: 5%;justify-content: center;word-break: break-word;${pagetitleSuffix.length > 35 ? 'margin-top:0; padding-top:0;' : ''}\">
            <span style=\"font-size: 18px; display: inline-flex;\">
            <div style=\"color:black;display: inline-flex;\">
            <img src=${analytics.reportlogo} style=\"width: 25px;
            height: 25px;
            padding: 0;
            padding: 0 5px;\"></img></div> <div className='report-title' 
            style=\" font-style: italic;
            color: #0093c2;
            font-size: 14px;
            \"><span style="color: black;font-size: 18px!important;font-style: normal;">${analytics.terms['Visit Summary']}</span> ${pagetitleSuffix}
            </div></span> </div>`
            if (document.getElementById('visitSummaryTemplate')) {
                var htmlMarkup = `<html>
                <head>
                <meta charset="utf-8">
                <link href='https://fonts.googleapis.com/css?family=Sarala' rel='stylesheet' type='text/css'>
                <link href='https://fonts.googleapis.com/css?family=Sarala:400,700' rel='stylesheet' type='text/css'>
                 <style>${visitsummaryStyles}</style>
                </head><body>${document.getElementById('visitSummaryTemplate').outerHTML}</body></html>`
                dispatch(toggleLoading(true))
                let postUrl = isDemo ? API_URL.DEMO_REPORT_DOWNLOAD : API_URL.DOWNLOAD_VISIT_SUMMARY
                axios.post(postUrl,
                    {
                        htmlMarkup,
                        fileName: '',
                        header,
                        visitId: 0,
                        document: '',
                        locale: '',
                        isMobile: isMobile
                    }).then(response => {
                        let urlParams = new URLSearchParams(history.location.search);
                        let download = urlParams.get('download')
                        let siteLogo = getState().analytics && getState().analytics.siteData && getState().analytics.siteData.logoFileName && getState().analytics.siteData.logoFileName.length > 0 ? true : false;
                        if (window.innerWidth <= 768 || siteLogo || download) {
                            if (isIE()) {
                                let pdfblob = b64toBlob('data:application/pdf;base64,' + response.data);
                                if (window.navigator.msSaveBlob) {
                                    window.navigator.msSaveOrOpenBlob(pdfblob, "summary.pdf");
                                }
                            }
                            else {
                                const downloadLink = document.createElement('a');
                                if (!isFirefox()) {
                                    let pdfblob = b64toBlob('data:application/pdf;base64,' + response.data);
                                    downloadLink.href = URL.createObjectURL(pdfblob)
                                } else {
                                    downloadLink.href = 'data:application/pdf;base64,' + response.data
                                }
                                downloadLink.className = 'hidden';
                                document.body.appendChild(downloadLink);
                                downloadLink.setAttribute('download', "summary.pdf");
                                downloadLink.click();
                                window.addEventListener('focus', e => { setTimeout(() => downloadLink.remove(), 300); URL.revokeObjectURL(downloadLink.href) }, { once: true });
                            }
                            dispatch(toggleLoading(false))
                            dispatch({
                                type: SET_PROPS,
                                payload: {
                                    isSummaryLoading: false,
                                    currentSummaryVisitId: null
                                }
                            })
                            setTimeout(() => {
                                let previousUrl = localStorage.getItem('previousurl')
                                let redirectTo = localStorage.getItem('redirectTo')
                                if (download) {
                                    history.location = {}
                                }
                                else if (history) {
                                    if (!previousUrl && !redirectTo) {
                                        if (localStorage.getItem('currenturl'))
                                            history.goBack()
                                        else {
                                            if (analytics.role == constants.logged_roles.PT && siteLogo) {
                                                history.push(`/analytics`)
                                            }
                                            else {
                                                history.push(`/visits/${visitId}`)
                                            }
                                        }
                                    }
                                    else if (((previousUrl && previousUrl.indexOf('analytics') != -1) ||
                                        (redirectTo && redirectTo.indexOf('analytics') != -1))
                                        && analytics.presentPatient) {
                                        if (analytics.role == constants.logged_roles.PT && siteLogo) {
                                            history.push(`/analytics`)
                                        }
                                        else {
                                            history.push(`/patients/${analytics.presentPatient.id}/analytics`)
                                        }
                                    }
                                    else {
                                        if (analytics.role == constants.logged_roles.PT && siteLogo) {
                                            history.push(`/analytics`)
                                        }
                                        else {
                                            history.push(`/visits/${visitId}`)
                                        }
                                    }
                                }
                                else {
                                    window.location.href = '/patients/list'
                                }
                            }, 1000);
                        }
                        else {
                            dispatch({
                                type: SET_PROPS,
                                payload: {
                                    downloadableFile: 'data:application/pdf;base64,' + response.data,
                                    isSummaryLoading: false,
                                    currentSummaryVisitId: null
                                }
                            })
                            document.title = 'summary.pdf'
                            dispatch(toggleLoading(false))
                        }
                        dispatch({
                            type: SET_PROPS,
                            payload: {
                                reportDownloaded: true
                            }
                        })
                    }).catch(error => {
                        dispatch(handleErrors(error));
                        dispatch(handleSummaryLoading(false))
                    })
            }
            dispatch({
                type: SET_PROPS,
                payload: {
                    isVisitSummary: false
                }
            })
        }, 8000);
    }
}
export function updateLoadingStatus(isInternetError) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isInternetError
            }
        })
    }
}

export function togglePlanModal(isToggle) {
    return (dispatch, getState) => {
        !isToggle ?
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendationErrors: []
                }
            }) : ''
        document.body.style.overflow = isToggle ? "hidden" : "auto"
        if (isToggle){
            dispatch(getCustomRecommendation(getState().analytics.presentPatient.siteId))
            dispatch(getCustomSigs(getState().analytics.presentPatient.siteId))
        }    
        dispatch({
            type: SET_PROPS,
            payload: {
                showPlanModal: isToggle,
                recommendationForm: { group: 'General Instructions' },
                recommendation_list: setRecommendationList(getState().analytics, 'General Instructions'),
                brand_list: setBrandList(getState().analytics, 'General Instructions')

            }
        })
        if (!isToggle) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    initialRecommendationForm: { group: 'General Instructions' },
                    recommendation_list: setRecommendationList(getState().analytics, 'General Instructions'),
                    brand_list: setBrandList(getState().analytics, 'General Instructions')
                }
            })
        }
    }
}

export function toggleMedicationModal(isToggle) {
    return (dispatch, getState) => {
        !isToggle ?
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendationErrors: []
                }
            }) : ''
        document.body.style.overflow = isToggle ? "hidden" : "auto"
        dispatch({
            type: SET_PROPS,
            payload: {
                showMedicationModal: isToggle,
                medicationForm: { group: 'Supplement' },
                recommendation_list: setRecommendationList(getState().analytics, 'Supplement'),
                brand_list: setBrandList(getState().analytics, 'Supplement')

            }
        })
        if (isToggle){
            dispatch(getCustomRecommendation(getState().analytics.presentPatient.siteId))
            dispatch(getCustomSigs(getState().analytics.presentPatient.siteId))
        }
        if (!isToggle) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    initialMedicationForm: { group: 'Supplement' },
                    recommendation_list: setRecommendationList(getState().analytics, 'Supplement'),
                    brand_list: setBrandList(getState().analytics, 'Supplement')
                }
            })
        }
    }
}

export function toggleResetPriority() {
    return (dispatch, getState) => {
        let currentState = getState().analytics

        let prioritiesMatch = true

        let currentPriorities = Array.from(simpleList.children)
            .map((ele) => parseInt(_.head(ele.children).getAttribute('data-id')))

        let initialPriorities = currentState && currentState.initialPriorities &&
            currentState.initialPriorities.sort((a, b) => {
                return b.priority < a.priority ? 1 : b.priority > a.priority ? -1 : 0
            }).map(ele => ele.recommendationId)

        currentPriorities.map((ele, index) => {
            if (initialPriorities[index] != ele) {
                prioritiesMatch = false
            }
        })

        dispatch({
            type: SET_PROPS,
            payload: {
                isReorder: prioritiesMatch ? false : true
            }
        })
    }
}

export function resetPriorities() {
    return (dispatch, getState) => {
        let currentState = getState().analytics
        if (!currentState || !currentState.presentPatient
            || !currentState.presentPatient.latestVisitId || !currentState.recommendations) {
            return
        }
        let visitId = currentState.presentPatient.latestVisitId
        let priorVisitId = currentState.presentPatient.priorVisitId != null ? currentState.presentPatient.priorVisitId : 0
        dispatch({
            type: SET_PROPS,
            payload: {
                isRecommendationsLoading: true
            }
        })

        let priorities = currentState.initialPriorities
        let patientId = currentState.presentPatient ? currentState.presentPatient.id : 0
        promisesList.push(axios.post(API_URL.RECOMMENDATION_REORDER, {
            visitId,
            // priorVisitId,
            patientId,
            priorities
        }).then(response => {
            let currentRecommendations = currentState.recommendations.map(item => {
                for (let i = 0; i < priorities.length; i++) {
                    if (item.id == priorities[i].recommendationId) {
                        return {
                            ...item,
                            priority: priorities[i].priority
                        }
                    }
                }
            })

            dispatch({
                type: SET_PROPS,
                payload: {
                    isRecommendationsLoading: false,
                    isReorder: false,
                    recommendations: currentRecommendations
                }
            })
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
    }
}

export function savePriorities() {
    return (dispatch, getState) => {
        let currentState = getState().analytics
        let visitId = currentState && currentState.presentPatient.latestVisitId
        let priorVisitId = currentState.presentPatient.priorVisitId != null ? currentState.presentPatient.priorVisitId : 0
        let patientId = currentState.presentPatient ? currentState.presentPatient.id : 0
        dispatch({
            type: SET_PROPS,
            payload: {
                // isRecommendationsLoading: true
                isPriorityLoader: true
            }
        })

        let priorities = Array.from(simpleList.children)
            .map((ele, index) => {
                return {
                    priority: index + 1,
                    recommendationId: parseInt(_.head(ele.children).getAttribute('data-id'))
                }
            })
        promisesList.push(axios.post(API_URL.RECOMMENDATION_REORDER, {
            visitId,
            // priorVisitId,
            patientId,
            priorities
        }).then(response => {
            Promise.all(promisesList.filter(p => p)).then(function () {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        isPriorityLoader: false
                    }
                })
                clearPromises()
            })
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
    }
}

export function editAnalyticsSurveyData(eve, surveyNo, quesNo) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false));
        let surveyData = getState().analytics && getState().analytics.surveyData ? getState().analytics.surveyData : [];
        let resultDTO = getState().analytics && getState().analytics.resultDTO ? getState().analytics.resultDTO : [];
        let validSurveyFields = getState().analytics && getState().analytics.validSurveyFields ? getState().analytics.validSurveyFields : {}
        let value = eve.target.value;
        if (resultDTO[surveyNo] && resultDTO[surveyNo].CreateObservationDTOs && resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo)) {
            if (surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).originalValue == null)
                if (value == "")
                    resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).changed = false
                else
                    resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).changed = true
            else
                if (surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).originalValue != value)
                    resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).changed = true
                else
                    resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).changed = false
            resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).value = value
            if (surveyData[surveyNo].questionsDTO && surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo) && surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO && (surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO.low || surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO.high)) {
                if (surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO.low && !surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO.high) {
                    if ((resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).value < surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO.low) && resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).value != "") {
                        validSurveyFields[surveyData[surveyNo].name].find((q) => q.id == quesNo).value = false;
                    }
                    else {
                        validSurveyFields[surveyData[surveyNo].name].find((q) => q.id == quesNo).value = true;
                    }
                }
                else if (!surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO.low && surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO.high) {
                    if ((resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).value > surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO.high) && resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).value != "") {
                        validSurveyFields[surveyData[surveyNo].name].find((q) => q.id == quesNo).value = false;
                    }
                    else {
                        validSurveyFields[surveyData[surveyNo].name].find((q) => q.id == quesNo).value = true;
                    }
                }
                else {
                    if ((resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).value < surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO.low || resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).value > surveyData[surveyNo].questionsDTO.find((q) => q.id == quesNo).dataRangesDTO.high) && resultDTO[surveyNo].CreateObservationDTOs.find((q) => q.id == quesNo).value != "") {
                        validSurveyFields[surveyData[surveyNo].name].find((q) => q.id == quesNo).value = false;
                    }
                    else {
                        validSurveyFields[surveyData[surveyNo].name].find((q) => q.id == quesNo).value = true;
                    }
                }
            }
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                resultDTO,
                validSurveyFields
            }
        })
    }
}

export function getAnalyticsSurveyData(visitId, locale) {
    return (dispatch, getState) => {
        if (visitId) {
            promisesList.push(axios.get(`${API_URL.QUESTIONNAIRE_QUESTIONS}?visitId=${visitId ? visitId : 0}&locale=${locale}`).then((response) => {
                let resultDTO = [];
                let validSurveyFields = {};
                response.data.map((obj, index) => {
                    let survey = {
                        orderId: obj.orderId,
                        visitId: visitId,
                        name: obj.name,
                        CreateObservationDTOs: []
                    }
                    validSurveyFields[obj.name] = [];
                    let CreateObservationDTOs = [];
                    obj.introductionsDTO ? obj.introductionsDTO.map((i) => {
                        i.questionsDTO.map((ques, index) => {
                            validSurveyFields[obj.name].push({ id: ques.id, value: true })
                            let observation = {
                                id: ques.id,
                                code: ques.noumenonDTO && ques.noumenonDTO.code ? ques.noumenonDTO.code : "",
                                valueType: ques.noumenonDTO && ques.noumenonDTO.valueType == "Numeric" ? "NM" : (ques.noumenonDTO.valueType == "Enumerable" ? "TX" : null),
                                identifier: "",
                                subIdentifier: "",
                                label: ques.question ? ques.question : "",
                                questionnaireLabel: ques.noumenonDTO && ques.noumenonDTO.name ? ques.noumenonDTO.name : "",
                                value: "",
                                units: ques.dataRangesDTO && ques.dataRangesDTO.units ? ques.dataRangesDTO.unit : null,
                                referenceRange: ques.noumenonDTO && ques.noumenonDTO.valueType == "Enumerable" ? null : (ques.dataRangesDTO && ques.dataRangesDTO ? (ques.dataRangesDTO.low && ques.dataRangesDTO.high ? ques.dataRangesDTO.low + "-" + ques.dataRangesDTO.high : (ques.dataRangesDTO.low && !ques.dataRangesDTO.high ? '>' + ques.dataRangesDTO.low : (ques.dataRangesDTO.high && !ques.dataRangesDTO.low) ? '<' + ques.dataRangesDTO.high : "")) : ""),
                                abnormalFlags: "",
                                resultStatus: "C",
                                isSurvey: true,
                                observationId: ques.observationId,
                                resultId: obj.resultId,
                                changed: false
                            }
                            CreateObservationDTOs.push(observation);
                        })
                    }) : null
                    obj.questionsDTO ? obj.questionsDTO.map((ques, index) => {
                        validSurveyFields[obj.name].push({ id: ques.id, value: true })
                        let observation = {
                            id: ques.id,
                            code: ques.noumenonDTO && ques.noumenonDTO.code ? ques.noumenonDTO.code : "",
                            valueType: ques.noumenonDTO && ques.noumenonDTO.valueType == "Numeric" ? "NM" : (ques.noumenonDTO.valueType == "Enumerable" ? "TX" : null),
                            identifier: "",
                            subIdentifier: "",
                            label: ques.question ? ques.question : "",
                            questionnaireLabel: ques.noumenonDTO && ques.noumenonDTO.name ? ques.noumenonDTO.name : "",
                            value: "",
                            units: ques.dataRangesDTO && ques.dataRangesDTO.units ? ques.dataRangesDTO.unit : null,
                            referenceRange: ques.noumenonDTO && ques.noumenonDTO.valueType == "Enumerable" ? null : (ques.dataRangesDTO && ques.dataRangesDTO ? (ques.dataRangesDTO.low && ques.dataRangesDTO.high ? ques.dataRangesDTO.low + "-" + ques.dataRangesDTO.high : (ques.dataRangesDTO.low && !ques.dataRangesDTO.high ? '>' + ques.dataRangesDTO.low : (ques.dataRangesDTO.high && !ques.dataRangesDTO.low) ? '<' + ques.dataRangesDTO.high : "")) : ""),
                            abnormalFlags: "",
                            resultStatus: "C",
                            isSurvey: true,
                            observationId: ques.observationId,
                            resultId: obj.resultId,
                            changed: false
                        }
                        CreateObservationDTOs.push(observation);
                    }) : null
                    survey.CreateObservationDTOs = CreateObservationDTOs;
                    resultDTO.push(survey);
                })
                response.data.map((res) => res.questionsDTO ? "" : res.questionsDTO = [])
                response.data.map((res) => res.introductionsDTO && res.introductionsDTO ? res.questionsDTO.push(res.introductionsDTO.map((i) => i.questionsDTO)) : '')
                response.data.map((res) => res.questionsDTO = res.questionsDTO.flat(2))
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        surveyData: response.data,
                        resultDTO,
                        validSurveyFields
                    }
                })
                response.data && response.data.length ? dispatch(toggleSurveyToaster(true)) : ''
            }).catch(error => {
                dispatch(toggleSurveyToaster(false))
                dispatch(handleErrors(error))
            }))
        }
    }
}

// export function editQuestionnaireForm(eve) {
//     return (dispatch, getState) => {
//         let key = eve.target.id;
//         let rating = eve.target.getAttribute('rating');
//         if (eve.target.name) {
//             key = eve.target.name;
//         }
//         let value = eve.target.value;
//         if (eve.target.type == 'checkbox') {
//             value = eve.target.checked ? 1 : 0
//         }
//         let questionnaireFormData = getState().analytics && getState().analytics.questionnaireFormData ? { ...getState().analytics.questionnaireFormData, [key]: value } : {}
//         let questionnaireRating = getState().analytics && getState().analytics.questionnaireRating ? { ...getState().analytics.questionnaireRating, [key]: rating } : {}
//         dispatch({
//             type: SET_PROPS,
//             payload: {
//                 questionnaireFormData,
//                 questionnaireRating
//             }
//         })
//     }
// }

export function editQuestionnairePageCount(change) {
    return (dispatch, getState) => {
        let surveyData = getState().analytics && getState().analytics.surveyData ? getState().analytics.surveyData : [];
        let questionnairePageCount = getState().analytics && getState().analytics.questionnairePageCount ? getState().analytics.questionnairePageCount : 1;
        let validSurveyFields = getState().analytics && getState().analytics.validSurveyFields ? getState().analytics.validSurveyFields : {};
        let surveyName = questionnairePageCount > surveyData.length ? surveyData[questionnairePageCount - 2].name : surveyData[questionnairePageCount - 1].name;
        let flag = true;
        validSurveyFields[surveyName].map((val) => {
            if (val.value == false)
                flag = false;
        })
        if (flag) {
            if (change == "add")
                questionnairePageCount += 1;
            else if (change == "sub")
                questionnairePageCount -= 1;
            else
                questionnairePageCount = change;
            dispatch({
                type: SET_PROPS,
                payload: {
                    questionnairePageCount: questionnairePageCount
                }
            })
        }
    }
}

export function submitAnalyticsSurveyForm(status) {
    return (dispatch, getState) => {
        let resultDTO = getState().analytics && getState().analytics.resultDTO ? _.cloneDeep(getState().analytics.resultDTO) : [];
        let surveyData = getState().analytics && getState().analytics.surveyData ? _.cloneDeep(getState().analytics.surveyData) : [];

        resultDTO = resultDTO.filter((survey) => {
            survey.CreateObservationDTOs = survey.CreateObservationDTOs.filter((ques) => {
                return ques.changed;
            })
            return true
        })
        let exixtingSurveyData = surveyData.filter((sData) => {
            return sData.resultId
        })
        let filledObservation = resultDTO.filter((survey) => {
            return survey.CreateObservationDTOs.length > 0
        })

        if (resultDTO.length > 0 && (exixtingSurveyData.length > 0 || filledObservation.length > 0)) {
            dispatch(toggleSurveyPopup(false));
            dispatch(toggleLoading(true))
            if (status == null)
                resultDTO = surveyData.map((sdata, sindex) => {
                    let statusArr = [];
                    sdata.questionsDTO.map((question) => {
                        if (resultDTO[sindex].CreateObservationDTOs.find((aa) => aa.code == question.noumenonDTO.code))
                            if (resultDTO[sindex].CreateObservationDTOs.find((aa) => aa.code == question.noumenonDTO.code).value != "")
                                statusArr.push(null)
                            else
                                statusArr.push(constants.visit_labels.pending)
                        else
                            if (question.value == null || question.value == "")
                                statusArr.push(constants.visit_labels.pending)
                            else
                                statusArr.push(null)
                    })
                    if (statusArr.includes(constants.visit_labels.pending))
                        status = constants.visit_labels.pending
                    else
                        status = null
                    if (resultDTO[sindex])
                        return {
                            ...resultDTO[sindex],
                            ...{ status: status },
                            ...{ resultId: surveyData.find((sData) => sData.orderId == resultDTO[sindex].orderId).resultId }
                        }
                })
            else
                resultDTO = resultDTO.map(survey => {
                    return {
                        ...survey,
                        ...{ status: status },
                        ...{ resultId: surveyData.find((sData) => sData.orderId == survey.orderId).resultId }
                    }
                })
            let survey = { resultDTO: resultDTO }
            let params = survey
            promisesList.push(axios.post(`${API_URL.QUESTIONNAIRE}`, { ...params }).then(() => {
                dispatch(handleLoading())
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        resultDTO: resultDTO,
                        surveySubmitted: true,
                        status: status
                    }
                })
                dispatch(toggleSurveyPopup(true));
            }).catch(error => {
                dispatch(handleErrors(error))
            }))
        }
    }
}

export function toggleSurveyPopup(value) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                surveyPopup: value
            }
        })
        if (!value && getState().analytics.surveySubmitted) {
            window.location.reload();
        }
    }
}

export function toggleHistoryRecommendationPopup(value) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                historyRecommendationPopup: value
            }
        })
    }
}

export function toggleStopRecommendationPopup(value, stopRecommendId, resumeRecommendation, recommendationCurrentRow) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                stopRecommendationPopup: value,
                stopRecommendId: stopRecommendId,
            }
        })
        if (recommendationCurrentRow) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendationCurrentRow: { ...recommendationCurrentRow }
                }
            })
        }
        if (!value) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    recommendationCurrentRow: null
                }
            })
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                resumeRecommendation: resumeRecommendation ? resumeRecommendation : false
            }
        })

    }
}

export function getHistoryRecommendations() {
    return (dispatch, getState) => {
        let id = getState().analytics && getState().analytics.presentPatient ? getState().analytics.presentPatient.latestVisitId : '';
        let locale = getState().analytics && getState().analytics.presentPatient ? getState().analytics.presentPatient.languagePreference : "en"
        let patientId = getState().analytics && getState().analytics.presentPatient ? getState().analytics.presentPatient.id : 0
        dispatch(toggleLoading(true))
        axios.get(`${API_URL.RECOMMENDATION_URL}/${id}?locale=${locale}&patientId=${patientId}&loadHistory=${true}`).then((response) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    filteredRecommendations: response.data,
                    historicRecommendations: response.data
                }
            })
            dispatch(toggleLoading(false))
            dispatch(toggleHistoryRecommendationPopup(true));
        }).catch((error) => {
            dispatch(toggleLoading(false))
            dispatch(handleErrors(error))
        })
    }
}

export function setRecommendationSearchFilters(eve) {
    return (dispatch, getState) => {
        let key = eve.target.id;
        let value = eve.target.value;
        let currentSearchRecm = getState() && getState().analytics && getState().analytics.currentSearchRecm ? getState().analytics.currentSearchRecm : {};
        currentSearchRecm = { ...currentSearchRecm, [key]: value };
        dispatch({
            type: SET_PROPS,
            payload: {
                currentSearchRecm
            }
        })
        dispatch(filterHistoryRecommendations());
    }
}

export function filterHistoryRecommendations() {
    return (dispatch, getState) => {
        let filteredRecommendations = getState().analytics && getState().analytics.historicRecommendations ? [...getState().analytics.historicRecommendations] : {};
        let searchName = getState().analytics && getState().analytics.currentSearchRecm && getState().analytics.currentSearchRecm.searchRecm ? getState().analytics.currentSearchRecm.searchRecm.toLowerCase() : '';
        filteredRecommendations = filteredRecommendations.filter(u =>
            (u.group && u.group.toLowerCase().indexOf(searchName) > -1) || (u.body && u.body.toLowerCase().indexOf(searchName) > -1) || (u.culturedNoumena && u.culturedNoumena.toLowerCase().indexOf(searchName) > -1) || (u.visitDate && moment(u.visitDate).format(getDateFormat(getState().analytics.presentPatient.datePreference)).toLowerCase().indexOf(searchName) > -1)
            || (u.notes && u.notes.toLowerCase().indexOf(searchName) > -1) || (u.instructions && u.instructions.toLowerCase().indexOf(searchName) > -1) || (u.timePoint && u.timePoint.toLowerCase().indexOf(searchName) > -1)
            || (u.stoppedAt && moment(u.stoppedAt).format(getDateFormat(getState().analytics.presentPatient.datePreference)).toLowerCase().indexOf(searchName) > -1) || (u.previousRecommendation && u.previousRecommendation.body && u.previousRecommendation.body.toLowerCase().indexOf(searchName) > -1)
            || (u.previousRecommendation && u.previousRecommendation.culturedNoumena && u.previousRecommendation.culturedNoumena.toLowerCase().indexOf(searchName) > -1)
            || (u.previousRecommendation && u.previousRecommendation.notes && u.previousRecommendation.notes.toLowerCase().indexOf(searchName) > -1) || (u.previousRecommendation && u.previousRecommendation.instructions && u.previousRecommendation.instructions.toLowerCase().indexOf(searchName) > -1)
            || (u.previousRecommendation && u.previousRecommendation.stoppedAt && moment(u.previousRecommendation.stoppedAt).format(getDateFormat(getState().analytics.presentPatient.datePreference)).toLowerCase().indexOf(searchName) > -1) || (u.nextRecommendation && u.nextRecommendation.createdAt && moment(u.nextRecommendation.createdAt).format(getDateFormat(getState().analytics.presentPatient.datePreference)).toLowerCase().indexOf(searchName) > -1)
        );
        dispatch({
            type: SET_PROPS,
            payload: {
                filteredRecommendations: filteredRecommendations
            }
        })
    }
}

export function toggleDrawerWarning(value) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                showDrawerWarning: value
            }
        })
    }
}

export function toggleSurveyToaster(value) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                showSurveyToaster: value
            }
        })
    }
}

export function toggleRecommendFormWarning(value) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                recommendFormWarning: value
            }
        })
    }
}

export function toggleMedicationFormWarning(value) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                medicationFormWarning: value
            }
        })
    }
}

export function toggleMetaRecommendations(value) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isMetaRecommendation: value
            }
        })
    }
}
export function setInitialRecommendationForm(group, brand, body, strength, recommendationStrengthType, instructions, notes, startedAt, endedAt, productType) {
    return (dispatch, getState) => {
        let initialRecommendationForm = { group: group, brand: brand, body: body, strength: strength, recommendationStrengthType: recommendationStrengthType, instructions: instructions, notes: notes, startedAt: startedAt, endedAt: endedAt, productType:productType }
        dispatch({
            type: SET_PROPS,
            payload: {
                initialRecommendationForm
            }
        })
    }
}


export function setMetaRecordIndex(metaIndex) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                metaRecordIndex: metaIndex
            }
        })
    }
}


export function setMetaRecord(metaRecord) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                metaRecord: metaRecord
            }
        })
    }
}

export function deleteMetaRecommendation(index, metaRec) {
    return (dispatch, getState) => {
        let metaRecommendations = getState().analytics && getState().analytics.metaRecommendations ? [...getState().analytics.metaRecommendations] : [];
        let metaRecExpanded = getState().analytics && getState().analytics.metaRecExpanded ? [...getState().analytics.metaRecExpanded] : [];
        metaRecommendations.splice(index, 1);
        metaRecExpanded.splice(index, 1);
        dispatch({
            type: SET_PROPS,
            payload: {
                metaRecommendations: metaRecommendations,
                metaRecExpanded: metaRecExpanded
            }
        })
        if (metaRec)
            dispatch(updateMetaRecommendationCodes(metaRec));
    }
}

export function setDefaultMetaExpansions() {
    return (dispatch, getState) => {
        let metaRecExpanded = [];
        let metaRecommendations = getState().analytics && getState().analytics.metaRecommendations ? [...getState().analytics.metaRecommendations] : [];
        for (let i = 0; i < metaRecommendations.length; i++)
            metaRecExpanded.push(false);
        dispatch({
            type: SET_PROPS,
            payload: {
                metaRecExpanded
            }
        })
    }
}

export function toggleMetaExpansion(index, val) {
    return (dispatch, getState) => {
        let metaRecExpanded = getState().analytics && getState().analytics.metaRecExpanded ? [...getState().analytics.metaRecExpanded] : [];
        metaRecExpanded[index] = val;
        dispatch({
            type: SET_PROPS,
            payload: {
                metaRecExpanded: metaRecExpanded
            }
        })
    }
}

export function updateMetaRecommendationCodes(metaRec) {
    return (dispatch, getState) => {
        let metaRecommendations = getState().analytics && getState().analytics.metaRecommendations ? [...getState().analytics.metaRecommendations] : [];
        let metaRecommendationCodes = getState().analytics && getState().analytics.metaRecommendationCodes ? [...getState().analytics.metaRecommendationCodes] : [];
        metaRec && metaRec.noumenaCodes && metaRec.noumenaCodes.length > 0 ? metaRec.noumenaCodes.forEach((metaCode) => {
            let flag = false;
            metaRecommendations.map((rec) => {
                if (rec.body != metaRec.body) {
                    rec && rec.noumenaCodes && rec.noumenaCodes.length > 0 ? rec.noumenaCodes.map((code) => {
                        if (code == metaCode)
                            flag = true;
                    }) : null;
                }
            })
            if (!flag) {
                let index = metaRecommendationCodes.findIndex((c) => {
                    return c == metaCode;
                });
                if (index != -1) {
                    metaRecommendationCodes.splice(index, 1);
                }
            }
        })
            : null;

        dispatch({
            type: SET_PROPS,
            payload: {
                metaRecommendationCodes: metaRecommendationCodes

            }
        })
    }
}

export function setAnalyticsUnitPreference(eve) {
    return (dispatch, getState) => {
        let key = eve.target.id;
        let value = eve.target.value;
        dispatch({
            type: SET_PROPS,
            payload: {
                [key]: value
            }
        })
    }
}

export function changeAnalyticsUnitPreference(noumenonCode, history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true));
        let siteId = getState().analytics && getState().analytics.presentPatient && getState().analytics.presentPatient.siteId ? getState().analytics.presentPatient.siteId : '';
        let units = getState().analytics && getState().analytics.analyticsUnitPreference ? getState().analytics.analyticsUnitPreference : '';
        let createCustomSiteUnitsDTO = { siteId, noumenonCode, units };
        axios.post(API_URL.PHYSIOAGE_DATA, { ...createCustomSiteUnitsDTO }).then(response => {
            dispatch(toggleLoading(false));
            history.push(`/patients/${getState().analytics.presentPatient.id}/analytics`);
            history.go(0);
        }).catch(error => {
            dispatch(toggleLoading(false));
            dispatch(handleErrors(error))
        })
    }
}

export function showNoFormChangesError(val) {
    return (dispatch, getState) => {
        let recommendationErrors = getState().analytics.recommendationErrors ? [...getState().analytics.recommendationErrors] : [];
        if (val)
            recommendationErrors.push(labels.no_recommandation_changes);
        else {
            let errIndex = recommendationErrors.indexOf(labels.no_recommandation_changes);
            recommendationErrors.splice(errIndex, 1);
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                recommendationErrors: recommendationErrors
            }
        })
    }
}

export function unMountingLoadingComponent(val) {
    return (dispatch, getState) => {
        if (val) {
            setTimeout(() => {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        unMountLoadingComponent: val
                    }
                })
            }, 600);
        }
        else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    unMountLoadingComponent: val
                }
            })
        }
    }
}

export function getAllRecommendations() {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        metadataAxios.get(process.env.REACT_APP_MEDICATION_BLOB_URL).then((response) => {
            let sortableKey = constants.recommendation_groups
            let groups_recommendations = response.data.groupDTO.map((rec) => { return { id: rec.id, value: rec.name } }).sort((a, b) => {
                return sortableKey.indexOf(a.value) - sortableKey.indexOf(b.value);
            });
            dispatch({
                type: SET_PROPS,
                payload: {
                    all_recommendations_data: response.data,
                    groups_recommendations: groups_recommendations,
                    brand_list: response.data.medicationBrandsDTO.map((brand) => { return { value: brand } }),
                    strengthTypes: response.data.strengthTypesDTO ? [...(constants.blankOption), ...response.data.strengthTypesDTO] : [],
                    loadingMedications: "finished"
                }
            })
            dispatch(toggleLoading(false))
        }).catch((error) => {
            dispatch(toggleLoading(false))
            dispatch(handleErrors(error))
        })
    }
}

export function clearParamsOfRecommendations() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                instruction_list: [],
                notes_list: [],
                strength_list: []
            }
        })
    }
}

export function openValueModel(value, label) {
    return (dispatch, getState) => {
        document.body.style.overflow = "hidden"
        dispatch({
            type: SET_PROPS,
            payload: {
                openValueModal: true,
                noumenonValue: value,
                noumenonLabel: label
            }
        })
    }
}

export function closeValueModel() {
    return (dispatch, getState) => {
        document.body.style.overflow = "auto"
        dispatch({
            type: SET_PROPS,
            payload: {
                openValueModal: false,
                noumenonValue: null,
                noumenonLabel: null
            }
        })
    }
}
export function isPatientReportLoaded(loaded) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                patientReportLoaded: loaded
            }
        })
    }
}
export function addToTableOfContentsArray(ids) {
    return (dispatch, getState) => {
        let tableOfContents = [];
        ids.map(s => tableOfContents.push("##" + s))
        dispatch({
            type: SET_PROPS,
            payload: {
                tableOfContents: tableOfContents,
            }
        });
    }
}

export function navigateHealthAnalytics(history, path) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentPatient: getState().providers,
            }
        })
        history.push(path)
    }
}

let round = function (value, scale) {
    return Number(Math.round(value + 'e' + scale) + 'e-' + scale).toFixed(scale);
}
const ACTION_HANDLERS = {
    [SET_PROPS]: (state, action) => {
        return Object.assign({}, state, { ...action.payload })
    }
}

const initialState = {
    visit_count: 0,
    isMenuActive: false,
    age: 0,
    logoFile: null,
    masthead_path_noLogo: masthead_blue,
    masthead_path_logo: masthead_white,
    categories: null,
    visit_count: 0,
    total_reviews: 0,
    presentPatient: {},
    currentPatientAging: {},
    currentVisit: {},
    lastVisitDate: null,
    units: [],
    bioInfo: [],
    biomarkerCount: 0,
    loadingData: "pending",
    loadingPatient: "pending",
    loadingCategories: "pending",
    loadingSections: "pending",
    loadingRecommendations: "pending",
    loadingMedications: "pending",
    downloadableFile: null,
    isInternetError: false,
    questionnaireFormData: {},
    questionnaireRating: {},
    questionnairePageCount: 1,
    surveySubmitted: false,
    surveyPopup: false,
    showSurveyToaster: false,
    showDrawerWarning: false,
    recommendFormWarning: false,
    showPlanModal: false,
    showMedicationModal: false,
    openValueModal: false,
    noumenonValue: null,
    noumenonLabel: null
}

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
export const actionCreators = {
    setCurrentHealthAnalytics,
    setBiomarkersOfAging,
    toggleMenu,
    toggleSideBar,
    closeSideBar,
    saveRecommendation,
    editRecommendation,
    getRecommendations,
    saveRecommendationNotes,
    updateRecommendations,
    newRecommendation,
    deleteRecommendations,
    providerDetails,
    getAllCategories,
    dataSelector,
    toggleDatumSideBar,
    exitAnalytics,
    resetLoader,
    downloadPatientReport,
    getTerms,
    loadDemoData,
    updateResolution,
    // populateRecommedations,
    downloadVisitSummary,
    setVisitSummary,
    handleDownloadVisitSummary,
    // hideSpectrum,
    setDemoStatus,
    setInitialHash,
    closeSideBarOnClose,
    updateLoadingStatus,
    togglePlanModal,
    toggleResetPriority,
    savePriorities,
    resetPriorities,
    toggleGradeSideBar,
    toggleCategorySideBar,
    toggleSectionSideBar,
    //editQuestionnaireForm,
    editQuestionnairePageCount,
    getAnalyticsSurveyData,
    editAnalyticsSurveyData,
    submitAnalyticsSurveyForm,
    toggleSurveyPopup,
    toggleSurveyToaster,
    toggleMetaRecommendations,
    updateMetaRecommendations,
    saveMetaRecommendationNotes,
    setMetaRecordIndex,
    deleteMetaRecommendation,
    setDefaultMetaExpansions,
    toggleMetaExpansion,
    setMetaRecord,
    updateMetaRecommendationCodes,
    toggleDrawerWarning,
    toggleRecommendFormWarning,
    setInitialRecommendationForm,
    toggleHistoryRecommendationPopup,
    getHistoryRecommendations,
    toggleStopRecommendationPopup,
    showNoFormChangesError,
    setRecommendationSearchFilters,
    filterHistoryRecommendations,
    setAnalyticsUnitPreference,
    changeAnalyticsUnitPreference,
    unMountingLoadingComponent,
    getAllRecommendations,
    toggleMedicationModal,
    editMedication,
    toggleMedicationFormWarning,
    saveMedication,
    updateMedication,
    setInitialMedicationForm,
    showNoMedicationChangesError,
    getMedication,
    deleteMedication,
    toggleStopMedicationPopup,
    clearParamsOfRecommendations,
    openValueModel,
    closeValueModel,
    isPatientReportLoaded,
    addToTableOfContentsArray,
    navigateHealthAnalytics,
    getCustomRecommendation,
    getCustomSigs
}