import moment from 'moment';
import _ from 'lodash';
import { parse } from 'qs';
import axios from '../../../lib/axios';
import API_URL from '../../../config/api';
import constants from '../../../config/constants';
import grader from '../../Analytics/tools/grader';
const SET_PROPS = 'SET_PROPS';
import { downloadCSV } from '../../../utils/download-csv-table';
import labels from '../../../config/localization';
import { toggleLoading, setPageTitle, setBreadCrumbs, handleErrors, toggleNotification, handleLoading, setLoggedInUser } from '../../../reducers/global-reducer';
import { clearPromises, promisesList } from '../../../utils/resolve-promises';
import en_labels from '../../../config/en_labels';
import { downloadExcel, downloadExcelImproved } from '../../../utils/download-utils';
import { roundNumber } from '../../Analytics/tools/helpers';


const excludeFields = [
    'Site',
    'Credits',
    'Total',
    'Category'
]


// function handleLoading() {
//     return (dispatch) => {
//         let allPromises = promisesList.filter(p => p)
//         Promise.all(allPromises).then(function (values) {
//             if (allPromises.length && allPromises.length == values.length) {
//                 clearPromises()
//                 dispatch(toggleLoading(false))
//             }
//         });
//     }
// }

function getVolumeReport() {
    return (dispatch) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(API_URL.VISIT_VOLUME_REPORT).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    volumereports: getVolumeReportData(response.data)
                }
            })
            dispatch(handleLoading())
            //dispatch(toggleLoading(false))
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
    }
}
function getVolumeReportData(data) {
    let reqdates = []
    for (let i = 0; i <= 11; i++) {
        reqdates.push(moment(new Date()).subtract(i, 'months').format('M/YYYY'))
    }
    let reports = []
    data.map(rd => {
        let reqObj = {
            Site: rd.siteName,
            Credits: rd.credits,
            Total: rd.total,
            Category: rd.category
        }
        reqdates.map(date => {
            let result = rd.dateCounts ? rd.dateCounts.filter(c => c.name == date) : []
            reqObj[date] = result && result.length ? _.head(result).value : ''
        })
        reports.push(reqObj)
    })
    var sortedReports = {}
    let groupedReports = _.groupBy(reports, 'Category')
    Object.keys(groupedReports).sort().map(t => sortedReports[t] = groupedReports[t])
    return reports && reports.length ? sortedReports : []
}
export function getSitesList() {
    return (dispatch) => {
        promisesList.push(axios.get(API_URL.SITES_NAMES).then((sites) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    sitesList: sites.data,
                }
            })
            dispatch(handleLoading())
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))
    }
}
function colorPalatteChange(eve) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentcolor: eve.target.value
            }
        })
    }
}
function downloadVolumeReport(filename) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let reqdates = []
        for (let i = 0; i <= 11; i++) {
            reqdates.push(moment(moment(new Date()).subtract(i, 'months').format('MM-DD-YYYY')).format("MMM-YY"))
        }
        let volumereports = getState().datamenu.volumereports
        if (volumereports && Object.keys(volumereports).length) {
            var final = []
            let headers = `${labels.noumena_labels.site}, ${labels.sites_labels.category_label}, ${[...reqdates.reverse()].join()}`
            final.push(headers)
            let result = Object.keys(volumereports).map(t => volumereports[t].map(i =>
                [`"${i.Site}"`, i.Category,
                _.remove(Object.keys(i), function (dates) {
                    return _.indexOf(excludeFields, dates) == -1
                }).reverse().map(k => i[k])]
            ))
            result.map(res => res.map(r => final.push(r.join())))
            downloadCSV(final.join('\n'), filename)
        }
        dispatch(toggleLoading(false))
    }
}
export function editNoumena(event, key) {
    return (dispatch, getState) => {
        let noumenaForm = getState().datamenu.noumenaForm
            ? { ...getState().datamenu.noumenaForm, [key ? key : event.target.id]: (key ? event : event.target.value) } : { [key ? key : event.target.id]: (key ? event : event.target.value) }
        dispatch({
            type: SET_PROPS,
            payload: {
                noumenaForm
            }
        })
    }
}
export function saveNoumena(history) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let noumenaForm = getState().datamenu.noumenaForm
        dispatch(toggleLoading(true))
        if (noumenaForm && noumenaForm.noumenaName && noumenaForm.noumenaName.length > 0) {
            var filteredCode = getState().datamenu.unitsData.filter(s => s.name == noumenaForm.noumenaName)
        }
        else {
            if (getState().datamenu.name && getState().datamenu.name != "") {
                noumenaForm = { ...getState().datamenu.noumenaForm, ["noumenaName"]: [getState().datamenu.name] }
                var filteredCode = []
                filteredCode.push({ "noumenonCode": getState().datamenu.name })
            }
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                noumenaResponse: null, noumenaData: null, isNoumenaExist: false
            }
        })
        var loggedInUser = getState() && getState().global && getState().global.loggedInUser

        let searchParamsObj = {
            noumenaName: noumenaForm && _.head(noumenaForm.noumenaName) ? _.head(filteredCode).noumenonCode : '',
            siteId: noumenaForm && noumenaForm.siteId && parseInt(noumenaForm.siteId) ? parseInt(noumenaForm.siteId) : '',
            gender: noumenaForm && noumenaForm.gender ? noumenaForm.gender : '',
            MaxDeviations: noumenaForm && noumenaForm.MaxDeviations != null && !isNaN(parseInt(noumenaForm.MaxDeviations)) ? parseInt(noumenaForm.MaxDeviations) : 0
        }
        let searchParamsFinalObj = Object.keys(searchParamsObj).filter(spj => ((spj == 'noumenaName' && searchParamsObj[spj]) || (spj == 'gender' && searchParamsObj[spj] && parseInt(searchParamsObj[spj]) != 0) || ((spj == 'siteId' || spj == 'MaxDeviations' || spj == 'page') && parseInt(searchParamsObj[spj])))).map(spj => `${spj}=${searchParamsObj[spj]}`)
        let searchUrl = searchParamsFinalObj.length ? `?page=1&${searchParamsFinalObj.join('&')}` : `?page=1`
        // `?noumenaName=${noumenaForm && _.head(noumenaForm.noumenaName) ? _.head(filteredCode).noumenonCode : ''}&siteId=${noumenaForm && noumenaForm.siteId && parseInt(noumenaForm.siteId) ? parseInt(noumenaForm.siteId) : ''}&gender=${noumenaForm && noumenaForm.gender ? noumenaForm.gender : ''}&MaxDeviations=${noumenaForm && noumenaForm.MaxDeviations ? parseInt(noumenaForm.MaxDeviations) : 5}`
        dispatch({
            type: SET_PROPS,
            payload: {
                currentNoumenaFilters: parse(searchUrl.substr(1), true)
            }
        })
        if (history && searchUrl)
            history.push(searchUrl)
        promisesList.push(axios.get(`${API_URL.NOUMENON_UPDATE}${searchUrl.replace('page', 'pageNumber')}`).then(response => {
            dispatch(setNoumenaData(response.data))
            let isNoumenaExist = false
            if (response.data == "") {
                isNoumenaExist = true
            }
            dispatch(handleLoading())
            dispatch({
                type: SET_PROPS,
                payload: {
                    noumenaResponse: response.data,
                    filteredCode: '',
                    isNoumenaExist,
                    pageNumber: 2
                }
            })
            //dispatch(toggleLoading(false))
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))
    }
}

export function compareNoumenas(history) {
    return (dispatch, getState) => {
        let noumenaForm = getState().datamenu.noumenaForm
        dispatch(toggleLoading(true))
        let filteredCodeOne;
        let filteredCodeTwo;
        if (noumenaForm && noumenaForm.firstNoumenaName && noumenaForm.firstNoumenaName.length > 0) {
            filteredCodeOne = getState().datamenu.unitsData.filter(s => s.name == noumenaForm.firstNoumenaName)
        }

        if (noumenaForm && noumenaForm.secondNoumenaName && noumenaForm.secondNoumenaName.length > 0) {
            filteredCodeTwo = getState().datamenu.unitsData.filter(s => s.name == noumenaForm.secondNoumenaName)
        }

        dispatch({
            type: SET_PROPS,
            payload: {
                noumenaResponse: null, noumenaData: null, isNoumenaExist: false
            }
        })

        let searchParamsObjOne = {
            noumenaName: noumenaForm && _.head(noumenaForm.firstNoumenaName) ? _.head(filteredCodeOne).noumenonCode : '',
            siteId: noumenaForm && noumenaForm.siteId && parseInt(noumenaForm.siteId) ? parseInt(noumenaForm.siteId) : '',
            gender: noumenaForm && noumenaForm.gender ? noumenaForm.gender : '',
            MaxDeviations: noumenaForm && noumenaForm.MaxDeviations != null && !isNaN(parseInt(noumenaForm.MaxDeviations)) ? parseInt(noumenaForm.MaxDeviations) : 0
        }

        let searchParamsObjTwo = {
            noumenaName: noumenaForm && _.head(noumenaForm.secondNoumenaName) ? _.head(filteredCodeTwo).noumenonCode : '',
            siteId: noumenaForm && noumenaForm.siteId && parseInt(noumenaForm.siteId) ? parseInt(noumenaForm.siteId) : '',
            gender: noumenaForm && noumenaForm.gender ? noumenaForm.gender : '',
            MaxDeviations: noumenaForm && noumenaForm.MaxDeviations != null && !isNaN(parseInt(noumenaForm.MaxDeviations)) ? parseInt(noumenaForm.MaxDeviations) : 0
        }
        let searchParamsFinalObjOne = Object.keys(searchParamsObjOne).filter(spj => ((spj == 'noumenaName' && searchParamsObjOne[spj]) || (spj == 'gender' && searchParamsObjOne[spj] && parseInt(searchParamsObjOne[spj]) != 0) || ((spj == 'siteId' || spj == 'MaxDeviations' || spj == 'page') && parseInt(searchParamsObjOne[spj])))).map(spj => `${spj}=${searchParamsObjOne[spj]}`)
        let searchParamsFinalObjTwo = Object.keys(searchParamsObjTwo).filter(spj => ((spj == 'noumenaName' && searchParamsObjTwo[spj]) || (spj == 'gender' && searchParamsObjTwo[spj] && parseInt(searchParamsObjTwo[spj]) != 0) || ((spj == 'siteId' || spj == 'MaxDeviations' || spj == 'page') && parseInt(searchParamsObjTwo[spj])))).map(spj => `${spj}=${searchParamsObjTwo[spj]}`)

        let searchUrlOne = searchParamsFinalObjOne.length ? `?page=1&${searchParamsFinalObjOne.join('&')}` : `?page=1`
        let searchUrlTwo = searchParamsFinalObjTwo.length ? `?page=1&${searchParamsFinalObjTwo.join('&')}` : `?page=1`

        let searchParamsObj = {
            fname: noumenaForm && _.head(noumenaForm.firstNoumenaName) ? _.head(filteredCodeOne).noumenonCode : '',
            sname: noumenaForm && _.head(noumenaForm.secondNoumenaName) ? _.head(filteredCodeTwo).noumenonCode : '',
            sid: noumenaForm && noumenaForm.siteId && parseInt(noumenaForm.siteId) ? parseInt(noumenaForm.siteId) : '',
            g: noumenaForm && noumenaForm.gender ? noumenaForm.gender : '',
            md: noumenaForm && noumenaForm.MaxDeviations != null && !isNaN(parseInt(noumenaForm.MaxDeviations)) ? parseInt(noumenaForm.MaxDeviations) : 0
        }

        let searchQueryString = objectToQueryString(searchParamsObj);
        let finalQueryString = '?' + searchQueryString;
        dispatch({
            type: SET_PROPS,
            payload: {
                currentCompareNoumenaFilterOne: parse(searchUrlOne.substr(1), true),
                currentCompareNoumenaFilterTwo: parse(searchUrlTwo.substr(1), true),
                isCompareNoumenaLoading: true
            }
        })
        if (history && searchUrlOne)
            history.push(finalQueryString)
        promisesList.push(axios.get(`${API_URL.NOUMENON_UPDATE}${searchUrlOne.replace('page', 'pageNumber')}`).then(response => {
            let isNoumenaExist = false
            if (response.data == "") {
                isNoumenaExist = true
            }
            dispatch(handleLoading())
            dispatch({
                type: SET_PROPS,
                payload: {
                    firstNoumenaResponse: response.data,
                    filteredCodeOne: '',
                }
            })
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))

        if (history && searchUrlTwo)
            history.push(finalQueryString)
        promisesList.push(axios.get(`${API_URL.NOUMENON_UPDATE}${searchUrlTwo.replace('page', 'pageNumber')}`).then(response => {
            // dispatch(setNoumenaData(response.data))
            let isNoumenaExist = false
            if (response.data == "") {
                isNoumenaExist = true
            }
            dispatch(handleLoading())
            dispatch({
                type: SET_PROPS,
                payload: {
                    secondNoumenaResponse: response.data,
                    filteredCodeTwo: '',
                }
            })
            //dispatch(toggleLoading(false))
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))

        dispatch({
            type: SET_PROPS,
            payload: {
                isCompareNoumenaLoading: false,
            }
        })
    }
}

function objectToQueryString(obj) {
    let str = [];
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            let encodedKey = encodeURIComponent(key);
            let value = obj[key];
            if (typeof value === 'object') {
                str.push(objectToQueryString(value, encodedKey));
            } else {
                if (!isEmptyOrWhitespace(encodeURIComponent(value)) && !(value == '0')) {
                    str.push(encodedKey + '=' + encodeURIComponent(value));
                }
            }
        }
    }
    return str.join('&');
}

function isEmptyOrWhitespace(str) {
    return !str || str.trim() === '';
}

export function setDefaultcompareNoumenas(pageNumber, history, urlParams) {
    return (dispatch, getState) => {
        dispatch(setLoggedInUser())
        dispatch(toggleLoading(true))
        
        dispatch({
            type: SET_PROPS,
            payload: {
                firstNoumenaResponse: null,
                secondNoumenaResponse: null
            }
        })
        Promise.all(promisesList).then(res => {
            var loggedInUser = getState() && getState().global && getState().global.loggedInUser

            if (loggedInUser && loggedInUser.role == constants.logged_roles.CG && typeof urlParams != 'undefined') {
                if (urlParams.sId) {
                    history.push('/statuscode/403')
                    return
                }
            }
            if (!getState().datamenu.sitesList){   //(loggedInUser && loggedInUser.role == constants.logged_roles.AN && !getState().datamenu.sitesList) {
                dispatch(getSitesList())
            }

            let searchParamsObjOne = {
                noumenaName: urlParams && urlParams.fname ? urlParams.fname : 'height',
                siteId: urlParams && urlParams.sId && parseInt(urlParams.sId) ? parseInt(urlParams.sId) : '',
                gender: urlParams && urlParams.g ? urlParams.g : '',
                MaxDeviations: urlParams && urlParams.md != null && !isNaN(parseInt(urlParams.md)) ? parseInt(urlParams.md) : 0
            }

            let searchParamsObjTwo = {
                page: pageNumber,
                noumenaName: urlParams && urlParams.sname ? urlParams.sname : 'weight',
                siteId: urlParams && urlParams.sId && parseInt(urlParams.sId) ? parseInt(urlParams.sId) : '',
                gender: urlParams && urlParams.g ? urlParams.g : '',
                MaxDeviations: urlParams && urlParams.md != null && !isNaN(parseInt(urlParams.md)) ? parseInt(urlParams.md) : 0
            }

            let searchParamsObj = {
                fname: urlParams && urlParams.fname ? urlParams.fname : 'height',
                sname: urlParams && urlParams.sname ? urlParams.sname : 'weight',
                sid: urlParams && urlParams.siteId && parseInt(urlParams.sId) ? parseInt(urlParams.sId) : '',
                g: urlParams && urlParams.g ? urlParams.g : '',
                md: urlParams && urlParams.md != null && !isNaN(parseInt(urlParams.md)) ?
                    parseInt(urlParams.md) : 0
            }

            let searchQueryString = objectToQueryString(searchParamsObj);

            let finalQueryString = '?' + searchQueryString;
            
            let searchParamsFinalObjOne = Object.keys(searchParamsObjOne).filter(spj => ((spj == 'noumenaName' && searchParamsObjOne[spj]) || (spj == 'gender' && searchParamsObjOne[spj] && parseInt(searchParamsObjOne[spj]) != 0) || ((spj == 'siteId' || spj == 'MaxDeviations' || spj == 'page') && parseInt(searchParamsObjOne[spj])))).map(spj => `${spj}=${searchParamsObjOne[spj]}`)
            let searchParamsFinalObjTwo = Object.keys(searchParamsObjTwo).filter(spj => ((spj == 'noumenaName' && searchParamsObjTwo[spj]) || (spj == 'gender' && searchParamsObjTwo[spj] && parseInt(searchParamsObjTwo[spj]) != 0) || ((spj == 'siteId' || spj == 'MaxDeviations' || spj == 'page') && parseInt(searchParamsObjTwo[spj])))).map(spj => `${spj}=${searchParamsObjTwo[spj]}`)

            let searchUrlOne = searchParamsFinalObjOne.length ? `?page=1&${searchParamsFinalObjOne.join('&')}` : `?page=1`;
            let searchUrlTwo = searchParamsFinalObjTwo.length ? `?page=23333&${searchParamsFinalObjTwo.join('&')}` : `?page=1`;
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentCompareNoumenaFilterOne: parse(searchUrlOne.substr(1), true),
                    currentCompareNoumenaFilterTwo: parse(searchUrlTwo.substr(1), true),
                    noumenaForm: { firstNoumenaName: ['Height'], secondNoumenaName: ['Weight'] },
                    isCompareNoumenaLoading: true
                }
            })
            if (history && searchUrlOne)
                history.push(finalQueryString)
                promisesList.push(axios.get(`${API_URL.NOUMENON_UPDATE}${searchUrlOne.replace('page', 'pageNumber')}`).then(response => {
                // dispatch(setNoumenaData(response.data))
                let isNoumenaExist = false
                if (response.data == "") {
                    isNoumenaExist = true
                }
                dispatch(handleLoading())
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        firstNoumenaResponse: response.data,
                        filteredCodeOne: '',
                    }
                })
                //dispatch(toggleLoading(false))
            }).catch((error) => {
                dispatch(handleErrors(error))
            }))

            if (history && searchUrlTwo)
                history.push(finalQueryString)
                promisesList.push(axios.get(`${API_URL.NOUMENON_UPDATE}${searchUrlTwo.replace('page', 'pageNumber')}`).then(response => {
                // dispatch(setNoumenaData(response.data))
                let isNoumenaExist = false
                if (response.data == "") {
                    isNoumenaExist = true
                }
                dispatch(handleLoading())
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        secondNoumenaResponse: response.data,
                        filteredCodeTwo: '',
                    }
                })
                //dispatch(toggleLoading(false))
            }).catch((error) => {
                dispatch(handleErrors(error))
            }))

            dispatch({
                type: SET_PROPS,
                payload: {
                    isCompareNoumenaLoading: false,
                }
            })
        })
        
    }
}

export function setDefaultNoumenon(urlparams, history) {
    return (dispatch, getState) => {
        dispatch(setLoggedInUser())
        Promise.all(promisesList).then(res => {
            var loggedInUser = getState() && getState().global && getState().global.loggedInUser
            let searchUrl = '?'
            if (Object.keys(urlparams).indexOf('page') == -1)
                urlparams.page = 1

            if (loggedInUser && loggedInUser.role == constants.logged_roles.CG) {
                if (urlparams.siteIds) {
                    history.push('/statuscode/403')
                    return
                }
                urlparams.siteIds = loggedInUser.siteIds
            }
            if (!getState().datamenu.sitesList){   //loggedInUser && loggedInUser.role == constants.logged_roles.AN && !getState().datamenu.sitesList) {
                dispatch(getSitesList())
            }

            searchUrl = `${searchUrl}${Object.keys(urlparams).map(up => `${up}=${urlparams[up]}`).join('&')}`
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentNoumenaFilters: parse(searchUrl.substr(1), true)
                }
            })
            dispatch(updateNoumenonValues(searchUrl, urlparams.page ? parseInt(urlparams.page) : 1))
        })
    }
}
export function updateNoumenonValues(searchUrl, pageNumber, code, headerType, history) {
    return (dispatch, getState) => {
        promisesList.push(axios.get(`${API_URL.NOUMENON_UPDATE}${searchUrl.replace('page', 'pageNumber')}`)
            .then(response => {
                dispatch(handleLoading())
                dispatch(setNoumenaData(response.data))
                let isNoumenaExist = false
                if (headerType == constants.preview) {
                    isNoumenaExist = false
                }

                if (headerType != constants.preview && response.data == "") {
                    isNoumenaExist = true
                }
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        noumenaResponse: response.data,
                        pageNumber: pageNumber + 1,
                        isNoumenaExist
                    }
                })
                dispatch(setHeaderForNoumena(getState().datamenu.noumenaResponse, code, headerType))
                //dispatch(toggleLoading(false))
            }).catch((error) => {
                dispatch(handleErrors(error))
            }))
    }
}

export function updateNoumenonNameValues(noumena_name, max_deviations, gender, history) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                noumenaData: null,
                noumenaResponse: null,
                noumenaData: null,
                filteredCode: ''
            }
        })

        let query = `?noumenaName=${noumena_name}`
        if (max_deviations) {
            query += `&MaxDeviations=${max_deviations}`
        }
        if (gender) {
            query += `&gender=${gender}`
        }
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(API_URL.NOUMENON_NAME + query)//API_URL.NOUMENON_NAME + query) 'http://localhost:3000/data'
            .then(response => {
                dispatch(handleLoading())
                dispatch(setNoumenaData(response.data))
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        noumenaResponse: response.data,
                        isNoumenaExist: false
                    }
                })
            }).catch((error) => {
                dispatch(handleErrors(error))
            }))
    }
}

export function setNoumenonValues(pageNumber, code, headerType, history) {
    return (dispatch, getState) => {
        var loggedInUser = getState() && getState().global && getState().global.loggedInUser

        dispatch({
            type: SET_PROPS,
            payload: {
                noumenaData: {},
                noumenaResponse: null,
                noumenaData: null,
                filteredCode: ''
            }
        })
        dispatch(toggleNotification(false))
        let noumenaForm = getState().datamenu.noumenaForm
        var filteredCode = getState().datamenu.unitsData ? getState().datamenu.unitsData.filter(s => s.name == noumenaForm ? noumenaForm.noumenaName : '') : ''
        dispatch(toggleLoading(true))
        let searchParamsObj = {
            page: pageNumber,
            noumenaName: noumenaForm && _.head(noumenaForm.noumenaName) ? (_.head(filteredCode) ? _.head(filteredCode).noumenonCode : '') : code ? code : '',
            siteId: (loggedInUser && loggedInUser.role == constants.logged_roles.CG
                ? loggedInUser.siteId
                : noumenaForm && noumenaForm.siteId && parseInt(noumenaForm.siteId) ? parseInt(noumenaForm.siteId) : ''),
            gender: noumenaForm && noumenaForm.gender ? noumenaForm.gender : '',
            MaxDeviations: noumenaForm && noumenaForm.MaxDeviations != null && !isNaN(parseInt(noumenaForm.MaxDeviations)) ? parseInt(noumenaForm.MaxDeviations) : 0
        }
        let searchUrl = `?${Object.keys(searchParamsObj)
            .filter(spj => ((spj == 'noumenaName' && searchParamsObj[spj]) || (spj == 'gender' && searchParamsObj[spj] && parseInt(searchParamsObj[spj]) != 0) || ((spj == 'siteId' || spj == 'MaxDeviations' || spj == 'page') && parseInt(searchParamsObj[spj]))))
            .map(spj => `${spj}=${searchParamsObj[spj]}`).join('&')}`
        if (history)
            history.push(searchUrl)
        dispatch({
            type: SET_PROPS,
            payload: {
                currentNoumenaFilters: parse(searchUrl.substr(1), true)
            }
        })
        dispatch(updateNoumenonValues(searchUrl, pageNumber, code, headerType, history))
        // promisesList.push(axios.get(`${API_URL.NOUMENON_UPDATE}${searchUrl.replace('page', 'pageNumber')}`)
        //     .then(response => {
        //         dispatch(handleLoading())
        //         dispatch(setNoumenaData(response.data))
        //         let isNoumenaExist = false
        //         if (headerType == constants.preview) {
        //             isNoumenaExist = false
        //         }

        //         if (headerType != constants.preview && response.data == "") {
        //             isNoumenaExist = true
        //         }
        //         dispatch({
        //             type: SET_PROPS,
        //             payload: {
        //                 noumenaResponse: response.data,
        //                 pageNumber: pageNumber + 1,
        //                 isNoumenaExist
        //             }
        //         })
        //         dispatch(setHeaderForNoumena(getState().datamenu.noumenaResponse, code, headerType))
        //         //dispatch(toggleLoading(false))
        //     }).catch((error) => {
        //         dispatch(handleErrors(error))
        //     }))
    }
}
function clearNoumenaForm() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                noumenaForm: {},
                currentNoumenaFilters: {},
                name: '',
                currentNoumenonNames: []
            }
        })
    }
}
export function setVadrValues(pageNumber, code, headerType, history) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                noumenaData: {},
                noumenaResponse: null,
                noumenaData: null,
                filteredCode: ''

            }
        })
        let noumenaForm = getState().datamenu.noumenaForm
        var filteredCode = getState().datamenu.unitsData ? getState().datamenu.unitsData.filter(s => s.name == noumenaForm ? noumenaForm.noumenaName : '') : ''
        dispatch(toggleLoading(true))
        if (history)
            history.push(`?page=${pageNumber}`)
        promisesList.push(axios.get(`${API_URL.VADR}?pageNumber=${pageNumber}`)
            .then(response => {
                dispatch(handleLoading())
                dispatch(setNoumenaData(response.data))
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        noumenaResponse: response.data,
                        pageNumber: pageNumber + 1
                    }
                })
                dispatch(setHeaderForNoumena(getState().datamenu.noumenaResponse, code, headerType))
                //dispatch(toggleLoading(false))
            }).catch((error) => {
                dispatch(handleErrors(error))
            }))
    }
}
export function setNoumenaData(noumenaResponse) {
    return (dispatch, getState) => {
        var noumenaData = {}
        noumenaResponse ? noumenaResponse.map(t => {
            // if (t.noumenonData && t.noumenonData.length > 0) {
            return ({
                [t.code]: {
                    nameOfNoumena: t.label,
                    scale: t.noumenonData && t.noumenonData.length && t.noumenonData[0].scale ? t.noumenonData[0].scale : 0,
                    male: t.noumenonData && t.noumenonData.length ? t.noumenonData.filter(s => s.gender == constants.male).map(r => ({ x: Math.round(parseInt(r.age)), y: roundNumber(parseFloat(r.value), r.scale), visitId: r.visitId })) : [],
                    female: t.noumenonData && t.noumenonData.length ? t.noumenonData.filter(s => s.gender == constants.female).map(r => ({ x: Math.round(parseInt(r.age)), y: roundNumber(parseFloat(r.value), r.scale), visitId: r.visitId })) : [],
                    label: t.noumenonData && t.noumenonData.length ? _.head(t.noumenonData).name ? _.head(t.noumenonData).name : labels.noumena_labels.no_name : '',
                    age: t.noumenonData && t.noumenonData.length ? t.noumenonData.map(r => roundNumber(parseFloat(r.age), 1)) : [],
                    agesForGoogleChart: t.noumenonData && t.noumenonData.length ? t.noumenonData.map(r => r.valueType == 'NM' ? (parseFloat(parseFloat(r.value).toFixed(3))) : r.value) : [],
                    gradePoints: t.noumenonData && t.noumenonData.length ? t.noumenonData.map(r => grader(parseFloat(r.points))) : [],
                    valueType: t.noumenonData && t.noumenonData.length ? t.noumenonData[0].valueType ? t.noumenonData[0].valueType : '' : '',
                }
            }
            )
            // }
        }).map(a => a ? noumenaData[_.head(Object.keys(a))] = a[_.head(Object.keys(a))] : '') : []
        dispatch({
            type: SET_PROPS,
            payload: {
                noumenaData,
            }
        })
    }
}
export function setUnitsData() {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(API_URL.NOUMENON).then((noumenaResp) => {
            dispatch(handleLoading())
            dispatch({
                type: SET_PROPS,
                payload: {
                    unitsData: noumenaResp.data,
                }
            })
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))
        //dispatch(toggleLoading(false))
    }
}
function setHeaderForNoumena(currentNoumena, code, headerType) {
    return (dispatch) => {
        if (headerType == constants.preview) {
            let noumenonName = currentNoumena ? currentNoumena.filter(s => ((s.noumenonData.length > 0) ? (s.noumenonData[0].code) : " ") == code)
                .map(p => p.noumenonData[0].name) : ''
            dispatch(setPageTitle(_.head(noumenonName)))
            document.title = `${labels.physioage_label}: ${noumenonName}`
            dispatch(setBreadCrumbs([
                { text: labels.physioage_label, path: '/patients/list' },
                { text: labels.noumena_labels.noumena, path: '/data/noumena' },
                { text: noumenonName, path: '' }
            ]))
        }
    }
}
export function populateSearchParam(name, eve) {
    return (dispatch, getState) => {
        let allNames = getState() && getState().datamenu ? getState().datamenu.unitsData : []
        let currentNoumenonNames = []
        if (name)
            allNames ? allNames.map(an => {
                if (an.name.toLowerCase().indexOf(name.toLowerCase()) != -1 && currentNoumenonNames.indexOf(an.name) == -1)
                    currentNoumenonNames.push(an.name)
            }) : ''
        dispatch({
            type: SET_PROPS,
            payload: {
                currentNoumenonNames,
                name
            }
        })
    }
}

export function populateFirstSearchParam(name, eve) {
    return (dispatch, getState) => {
        let allNames = getState() && getState().datamenu ? getState().datamenu.unitsData.filter(x => x.name != _.head(getState().datamenu.noumenaForm.secondNoumenaName)) : []
        let currentNoumenonNamesOne = []
        if (name)
            allNames ? allNames.map(an => {
                if (an.name.toLowerCase().indexOf(name.toLowerCase()) != -1 && currentNoumenonNamesOne.indexOf(an.name) == -1)
                    currentNoumenonNamesOne.push(an.name)
            }) : ''
        dispatch({
            type: SET_PROPS,
            payload: {
                currentNoumenonNamesOne
            }
        })
    }
}

export function populateSecondSearchParam(name, eve) {
    return (dispatch, getState) => {
        let allNames = getState() && getState().datamenu ? getState().datamenu.unitsData.filter(x => x.name != _.head(getState().datamenu.noumenaForm.firstNoumenaName)) : []
        let currentNoumenonNamesTwo = []
        if (name)
            allNames ? allNames.map(an => {
                if (an.name.toLowerCase().indexOf(name.toLowerCase()) != -1 && currentNoumenonNamesTwo.indexOf(an.name) == -1)
                    currentNoumenonNamesTwo.push(an.name)
            }) : ''
        dispatch({
            type: SET_PROPS,
            payload: {
                currentNoumenonNamesTwo
            }
        })
    }
}

export function clearNoumenaNames() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentNoumenonNames: []
            }
        })
    }
}

export function clearCompareNoumenaNamesForFirstNoumena() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentNoumenonNamesOne: []
            }
        })
    }
}

export function clearCompareNoumenaNamesForSecondNoumena() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentNoumenonNamesTwo: []
            }
        })
    }
}

function setNoumenonVadrValues() {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(API_URL.NOUMENON).then((noumenaResp) => {
            var unitsData = noumenaResp.data
            var vadrData = []
            constants.sampleNoumenonData.map(t => {
                return { [t.code]: { male: t.noumenonData.filter(s => s.gender == constants.male).map(r => [Math.round(parseInt(r.age)), parseInt(r.value)]), female: t.noumenonData.filter(s => s.gender == constants.female).map(r => [Math.round(parseInt(r.age)), parseInt(r.value)]) } }
            }).map(a => a ? vadrData[_.head(Object.keys(a))] = a[_.head(Object.keys(a))] : '')
            dispatch(handleLoading())
            dispatch({
                type: SET_PROPS,
                payload: {
                    vadrData, unitsData,
                }
            })
            //dispatch(toggleLoading(false))
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))
    }

}
export function downloadCSVNoumena(code) {
    return (dispatch, getState) => {
        var nouData = getState().datamenu.noumenaResponse.filter(s => s.code == code).map(t => t.noumenonData.map(q => q))
        let noumenonCSV = _.head(nouData)
        // let csv = []
        if (noumenonCSV.length) {
            const reqlabels = {
                code: labels.noumena_labels.code,
                gender: labels.noumena_labels.gender,
                age: labels.noumena_labels.age,
                visitDate: labels.noumena_labels.visitDate,
                // patientId: "Patient Id",
                visitId: labels.noumena_labels.visitId,
                ordinal: labels.noumena_labels.ordinal,
                siteId: labels.noumena_labels.siteId,
                value: `${code}`,
                units: `${code} ${labels.noumena_labels.units}`
            }
            const headers = Object.keys(reqlabels).map(r => {
                return reqlabels[r]
            })

            // csv.push(headers.join())
            // noumenonCSV.map(c => csv.push(Object.keys(reqlabels).map(r => r == 'visitDate' ? new Date(c[r]).toLocaleDateString() : r == 'age' ? Math.round(c[r]) : r == "value" && c[r] == " " ? "0" : c[r]).join()))
            // downloadCSV(csv.join('\n'), en_labels.noumena_labels.noumena_download)

            const aoa = [headers]
            // noumenonCSV.map(c => aoa.push(Object.keys(reqlabels).map(r => r == 'visitDate' ? new Date(c[r]).toLocaleDateString() : r == 'age' ? parseFloat(Math.floor(c[r])) : r == "value" && c[r] == " " ? "0" : returnNumber(c[r]))))
            noumenonCSV.map(c => aoa.push(Object.keys(reqlabels).map(r => {

                if (r == 'visitDate') {
                    return moment(new Date(c[r])).format("MM/DD/YYYY")
                }
                else if (r == 'visitId') {
                    return parseFloat(c[r])
                }
                // else if (r == 'patientId') {
                //     return parseFloat(c[r])
                // }
                else if (r == 'siteId') {
                    return parseFloat(c[r])
                }
                else if (r == 'age') {
                    // return parseFloat(Math.floor(c[r]))
                    return parseFloat(roundNumber(c[r], 1))
                }
                else if (r == "value") {
                    if (c[r] == " ")
                        return "0"
                    else
                        return returnNumber(c[r], c['valueType'], c['scale'])
                }
                else if (r == 'ordinal') {
                    return parseFloat(c[r])
                }
                else {
                    return c[r]
                }
            })))

            downloadExcelImproved(aoa, en_labels.noumena_labels.noumena, code + '_data.xlsx')
        }
    }
}

export function downloadCSVCompareNoumena(firstNoumenacode, secondNoumenaCode) {
    return (dispatch, getState) => {
        const state = getState().datamenu;
        const firstNoumenaResponse = state.firstNoumenaResponse;
        const secondNoumenaResponse = state.secondNoumenaResponse;

        const firstNoumenaData = firstNoumenaResponse.map(t => t.noumenonData).flat();
        const secondNoumenaData = secondNoumenaResponse.map(t => t.noumenonData).flat();

        if (firstNoumenaData.length === 0 && secondNoumenaData.length === 0) {
            return; // No data to process
        }

        const reqlabels = {
            patientId: labels.noumena_labels.patientId,
            visitId: labels.noumena_labels.visitId,
            visitDate: labels.noumena_labels.visitDate,
            gender: labels.noumena_labels.gender,
            age: labels.noumena_labels.age,
            ordinal: labels.noumena_labels.ordinal,
            siteId: labels.noumena_labels.siteId,
            firstNomenaValue: `${_.head(firstNoumenaData).name}`,
            firstNoumenaUnits: `${_.head(firstNoumenaData).name} ${labels.noumena_labels.units}`,
            secondNomenaValue: `${_.head(secondNoumenaData).name}`,
            secondNoumenaUnits: `${_.head(secondNoumenaData).name} ${labels.noumena_labels.units}`
        };

        const headers = Object.values(reqlabels);

        const visitIdSet = new Set([...firstNoumenaData.map(item => item.visitId), ...secondNoumenaData.map(item => item.visitId)]);
        const uniqueVisitIds = Array.from(visitIdSet).sort((a, b) => a - b);

        const firstNoumenaMap = new Map(firstNoumenaData.map(item => [item.visitId, item]));
        const secondNoumenaMap = new Map(secondNoumenaData.map(item => [item.visitId, item]));

        const aoa = [headers];
        uniqueVisitIds.forEach(id => {
            const firstItem = firstNoumenaMap.get(id) || {};
            const secondItem = secondNoumenaMap.get(id) || {};

            aoa.push([
                firstItem.patientId || secondItem.patientId || '',
                parseFloat(id),
                moment(firstItem.visitDate || secondItem.visitDate).format("MM/DD/YYYY"),
                firstItem.gender || secondItem.gender || '',
                parseFloat(roundNumber(firstItem.age || secondItem.age || 0, 1)),
                firstItem.ordinal || secondItem.ordinal || '',
                parseFloat(firstItem.siteId || secondItem.siteId || 0),
                firstItem.value ? returnNumber(firstItem.value, firstItem.valueType, firstItem.scale) : 'NA',
                firstItem.units || 'NA',
                secondItem.value ? returnNumber(secondItem.value, secondItem.valueType, secondItem.scale) : 'NA',
                secondItem.units || 'NA'
            ]);
        });

        downloadExcelImproved(aoa, labels.noumena_labels.compare_noumena, `${_.head(firstNoumenaData).name}_${_.head(secondNoumenaData).name}_data_compare.xlsx`);
    }
}

function returnNumber(value, valuetype, scale) {
    if ((valuetype != "NM") || ((value.toString().match(/[<>]/)) || isNaN(parseFloat(value)))) {
        return value;
    }
    else {
        return parseFloat(roundNumber(parseFloat(value), scale));
    }
}
const ACTION_HANDLERS = {
    [SET_PROPS]: (state, action) => {
        return Object.assign({}, state, { ...action.payload })
    }
}

const initialState = {
    volumereports: [],
    currentcolor: 'palette_teal_to_brown',
    noumenaResponse: [],
    noumenaData: [],
    isNoumenaExist: false
}

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : state
}

export const actionCreators = {
    getVolumeReport,
    colorPalatteChange,
    downloadVolumeReport,
    setNoumenonValues,
    getSitesList,
    populateSearchParam,
    setNoumenonVadrValues,
    editNoumena,
    saveNoumena,
    downloadCSVNoumena,
    setHeaderForNoumena,
    setUnitsData,
    setVadrValues,
    updateNoumenonValues,
    setDefaultNoumenon,
    clearNoumenaForm,
    clearNoumenaNames,
    updateNoumenonNameValues,
    clearCompareNoumenaNamesForFirstNoumena,
    clearCompareNoumenaNamesForSecondNoumena,
    compareNoumenas,
    setDefaultcompareNoumenas,
    populateFirstSearchParam,
    populateSecondSearchParam,
    downloadCSVCompareNoumena
}